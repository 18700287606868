import { Box, Center, Icon, Link, Flex, useColorMode } from '@chakra-ui/react';
import type { LinkProps, FlexProps } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { mode } from '@chakra-ui/theme-tools';
import React, { FC, ReactElement } from 'react';
import { ChevronRight } from '@mui/icons-material';

type Props = LinkProps & FlexProps;

export interface BallotLinkProps extends Props {
  href: string;
  isExternal?: boolean;
}

export const BallotLink: FC<BallotLinkProps> = ({
  children,
  href,
  width,
  isExternal,
  ...rest
}): ReactElement => {
  const modeProps = useColorMode();

  return (
    <Center
      as={Link}
      href={href}
      variant="ballot"
      isExternal={isExternal}
      width={width || ['90%', '70%', '60%']}
      {...rest}
    >
      <Flex
        minHeight="min-content"
        flexGrow={1}
        color={mode('brand.primary', 'brand.yellow')(modeProps)}
      >
        {children}
      </Flex>
      <Box marginLeft={3}>
        {isExternal ? (
          <Icon
            as={ExternalLinkIcon}
            w={4}
            h={4}
            mb={1}
            color={mode('brand.blue', 'brand.yellow')(modeProps)}
          />
        ) : (
          <Icon
            as={ChevronRight}
            color={mode('brand.primary', 'brand.yellow')(modeProps)}
          />
        )}
      </Box>
    </Center>
  );
};

export default BallotLink;
