import { List, ListItem, Text } from '@chakra-ui/react';
import React, { Fragment } from 'react';

import { BallotLink } from '..';
import { Position } from '../../types/ballotReady';
import { capitalize } from '../../utils/helpers';

import BallotListWrapper from './BallotListWrapper';

type PickByType<T, Value> = {
  [P in keyof T as T[P] extends Value ? P : never]: T[P];
};

type RequiredStringTypesOfPosition = PickByType<Position, string>;

interface BallotContentProps {
  link: string;
  positions: Position[];
  attribute: keyof RequiredStringTypesOfPosition;
  countDescriptor: 'categories' | 'candidates' | 'races';
  linkWidth?: string | number;
}

const BallotContent = ({
  link,
  positions,
  attribute,
  countDescriptor,
}: BallotContentProps) => (
  <Fragment>
    <BallotListWrapper
      length={positions.length}
      countDescriptor={countDescriptor}
    >
      <List>
        {positions &&
          attribute &&
          positions.map((position: Position) => (
            <ListItem
              overflow="clip"
              key={position.position_id}
              w="100%"
              margin="auto"
            >
              <BallotLink
                key={position.position_id}
                href={`${link}${position[attribute]?.toLowerCase()}`}
                width="100%"
                mb="20px"
                overflow="clip"
              >
                <Text noOfLines={3}>{capitalize(position[attribute])}</Text>
              </BallotLink>
            </ListItem>
          ))}
      </List>
    </BallotListWrapper>
  </Fragment>
);

export default BallotContent;
