/* eslint-disable react/jsx-no-undef */
import { Box, Text, useColorMode } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import type { TextProps } from '@chakra-ui/react';
import React, { FC, ReactElement } from 'react';

import { useBallotData } from '../../contexts';
import { formatDate } from '../../utils/helpers';

interface ElectionLabelProps extends TextProps {
  electionId?: number;
}

export const ElectionLabel: FC<ElectionLabelProps> = ({
  electionId,
  ...rest
}): ReactElement | null => {
  const { elections, isLoading } = useBallotData();

  const modeProps = useColorMode();

  const elecId =
    electionId ??
    Number(window.location.pathname.match(/\/ballot\/(\d+)\/.*/)?.at(1));

  const election = elections.find((e) => Number(e.id) === Number(elecId));

  if (isLoading) {
    return <></>;
  }

  return (
    <Box
      borderLeft="4px solid"
      borderColor={mode('brand.primary', 'brand.white')(modeProps)}
      color={mode('brand.primary', 'brand.white')(modeProps)}
      pl="8px"
      h="50px"
      fontWeight="bold"
      {...rest}
    >
      <Text textStyle="h4">{election?.name}</Text>

      {election && (
        <Text fontWeight="normal" fontStyle="italic" textStyle="h5">
          {formatDate(election.election_day)}
        </Text>
      )}
    </Box>
  );
};
