import {
  BreadcrumbItem as ChakraBreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import type { BreadcrumbItemProps } from '@chakra-ui/react';
import React, { FC } from 'react';

interface Props extends BreadcrumbItemProps {
  route: string;
  isCurrentPage: boolean;
  text: string;
}

export const BreadcrumbItem: FC<Props> = ({
  route,
  isCurrentPage,
  text,
  ...rest
}) => (
  <ChakraBreadcrumbItem {...rest}>
    <BreadcrumbLink isCurrentPage={isCurrentPage} href={route}>
      {text}
    </BreadcrumbLink>
  </ChakraBreadcrumbItem>
);
