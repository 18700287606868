import React, { FC, ReactElement } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';

import { capitalize } from '../../utils/helpers';
import { Candidate, Election } from '../../types/ballotReady';
import { BallotInterface } from '../../pages/ballot/FavoritesPage';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf',
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    margin: 50,
    fontFamily: 'Open Sans',
  },
  heading: {
    marginBottom: '15px',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  subheading: { marginBottom: '15px', fontSize: '12px', fontWeight: 'bold' },
  level: {
    marginBottom: '15px',
    fontSize: '12px',
    textDecoration: 'underline',
  },
  position: { marginBottom: '5px', fontSize: '12px' },
  candidate: { flex: 1, paddingLeft: 6, marginTop: 2, fontSize: '12px' },
});

interface BallotPrintProps {
  ballot: BallotInterface;
  elections: Election[];
}

interface CandidateBulletProps {
  candidate: Candidate;
}

const CandidateBullet: FC<CandidateBulletProps> = ({ candidate }) => (
  <View style={{ flexDirection: 'row' }}>
    <Text key={candidate.id}>{'\u2022'}</Text>
    <Text key={candidate.last_name} style={styles.candidate}>
      {candidate.first_name} {candidate.last_name} ({candidate.party_name})
    </Text>
  </View>
);

const sumCandidates = (election: BallotInterface[0]) => {
  const sum = Object.values(election).reduce((acc, curr) => {
    const subSum = Object.values(curr).reduce(
      (subAcc, subCurr) => subAcc + subCurr.candidates.length,
      0,
    );

    return subSum + acc;
  }, 0);

  return sum;
};

export const BallotPrint: FC<BallotPrintProps> = ({
  ballot,
  elections,
}): ReactElement => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.heading}>My Ballot</Text>
      {Object.entries(ballot).map(([electionId, election]) => {
        const electionName = elections.find(
          (e) => Number(e.id) === Number(electionId),
        );

        return (
          <View key={electionId}>
            {sumCandidates(election) ? (
              <Text key={electionId} style={styles.subheading}>
                {electionName && capitalize(electionName?.name)}
              </Text>
            ) : (
              <></>
            )}

            {Object.entries(election).map(([level, raceMap]) => {
              const hasCandidates =
                Object.values(raceMap).reduce(
                  (a, b) => a + b.candidates.length,
                  0,
                ) > 0;

              return (
                <View key={level}>
                  {hasCandidates ? (
                    <Text key={level} style={styles.level}>
                      {level && capitalize(level)}
                    </Text>
                  ) : (
                    <></>
                  )}
                  <View>
                    {Object.entries(raceMap).map(([race, value]) => (
                      <View key={race}>
                        {value.candidates.length ? (
                          <Text style={styles.position}>{race}</Text>
                        ) : (
                          <></>
                        )}
                        <View style={{ marginLeft: '20px' }}>
                          {value.candidates.length ? (
                            value.candidates.map((candidate) => (
                              <CandidateBullet
                                key={candidate.id}
                                candidate={candidate}
                              />
                            ))
                          ) : (
                            <></>
                          )}
                        </View>
                      </View>
                    ))}
                  </View>
                </View>
              );
            })}
          </View>
        );
      })}
    </Page>
  </Document>
);

export default BallotPrint;
