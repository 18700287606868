import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';

import './styles/styles.scss';

import { App } from './app';
import theme from './theme';
import ProviderWrapper from './contexts/AppContext';
import { sendWebVitals } from './utils/analytics';

// Use for debugging locally if needed
// TODO: have this set by a debug ENV variable
// console.log(theme);

ReactDOM.render(
  <React.StrictMode>
    <ProviderWrapper>
      <ChakraProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </ChakraProvider>
    </ProviderWrapper>
  </React.StrictMode>,

  document.getElementById('root'),
);

sendWebVitals({
  analyticsId: process.env.REACT_APP_VERCEL_ANALYTICS_ID,
  debug: false, // TODO: have this set by a debug ENV variable
  path: window.location.pathname,
});
