import React, {
  createContext,
  FC,
  ReactElement,
  useState,
  useEffect,
  useContext,
} from 'react';

import type { VotingMethodType } from '../types/contentful/votingMethod';

export type SetVotingMethodType = React.Dispatch<
  React.SetStateAction<VotingMethodType | null>
>;
interface VotingMethodInterface {
  votingMethod: VotingMethodType | null;
  setVotingMethod: SetVotingMethodType;
}

const VotingMethodContext = createContext<VotingMethodInterface | undefined>(
  undefined,
);

//@TODO: Put all localstorage keys in a localstorage constants file
export const VOTING_METHOD_KEY = 'votingMethod';

const VotingMethodContextProvider: FC = ({ children }): ReactElement => {
  const [votingMethod, setVotingMethod] = useState<VotingMethodType | null>(
    localStorage.getItem(VOTING_METHOD_KEY) as VotingMethodType,
  );

  useEffect(() => {
    votingMethod && localStorage.setItem(VOTING_METHOD_KEY, votingMethod);
  }, [votingMethod]);
  return (
    <VotingMethodContext.Provider value={{ votingMethod, setVotingMethod }}>
      {children}
    </VotingMethodContext.Provider>
  );
};

export default VotingMethodContextProvider;

export const useVotingMethod = () => {
  const context = useContext(VotingMethodContext);

  if (!context) {
    throw new Error(
      'useVotingMethod must be used inside the VotingMethodContext provider',
    );
  }

  return context;
};
