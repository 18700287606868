import React, { FC, ReactElement, ReactNode } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from '@chakra-ui/react';
import type {
  AccordionProps as ChakraAccordionProps,
  AccordionButtonProps,
} from '@chakra-ui/react';

interface AccordionProps extends ChakraAccordionProps {
  headerText?: string;
  panelText?: ReactNode;
  buttonProps?: AccordionButtonProps;
}

export const InfoAccordion: FC<AccordionProps> = ({
  headerText,
  panelText,
  buttonProps,
  ...rest
}): ReactElement => (
  <Accordion variant="faq" allowMultiple width="100%" {...rest}>
    <AccordionItem>
      <h2>
        <AccordionButton {...buttonProps}>
          <Text textStyle={'h4'} flex="1" textAlign="left">
            {headerText}
          </Text>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel>{panelText}</AccordionPanel>
    </AccordionItem>
  </Accordion>
);

export default InfoAccordion;
