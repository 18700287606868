import { Text, UnorderedList, ListItem } from '@chakra-ui/react';
import React from 'react';

export const markdownTheme = {
  // TODO: made props "any" but there should be a better type
  p: (props: any) => {
    const { children } = props;

    return <Text mb={4}>{children}</Text>;
  },
  h1: (props: any) => {
    const { children } = props;
    return (
      <Text as="h2" textStyle="h2">
        {children}
      </Text>
    );
  },
  h2: (props: any) => {
    const { children } = props;
    return (
      <Text as="h3" textStyle="h3" variant="markdownLabel" py={2} pl={2} my={3}>
        {children}
      </Text>
    );
  },
  ul: (props: any) => {
    const { children } = props;
    return (
      <UnorderedList pl={4} spacing={1} mb={4}>
        {children}
      </UnorderedList>
    );
  },
  li: (props: any) => {
    const { children } = props;
    return <ListItem fontSize="14px">{children}</ListItem>;
  },
};
