import { ListItem } from '@chakra-ui/react';
import React, { ReactElement, FC } from 'react';

import { useFavoritedCandidates } from '../../contexts';
import { useIsDesktop, useIsMobile } from '../../hooks';
import { Candidate, Position } from '../../types/ballotReady';
import CandidateCard from '../card/CandidateCard';
import { CandidateCardWrapper } from '../card/CandidateCardWrapper';
import { MobileCandidateCard } from '../card/MobileCandidateCard';

import CandidateLayout from './CandidateLayout';

interface CandidateGridProps {
  candidates: Candidate[];
  positions?: Position[];
  candidateBioPath?: string;
}

export const CandidateGrid: FC<CandidateGridProps> = ({
  candidates,
  positions,
  candidateBioPath,
}): ReactElement => {
  const { favoriteCandidates } = useFavoritedCandidates();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();

  const getCandidateKey = (candidate: Candidate): string =>
    String(
      candidate.id +
        candidate.position_id +
        candidate.first_name +
        candidate.last_name,
    );

  const isDefaultChecked = (candidate: Candidate): boolean | undefined =>
    favoriteCandidates.some(
      (el) =>
        el.name === `${candidate.first_name} ${candidate.last_name}` &&
        el.id === String(candidate.id),
    );

  const getPositionName = (positionId: string) => {
    const position = positions?.find(
      (position) => String(position.position_id) === positionId,
    );
    return position?.name;
  };

  return (
    <CandidateLayout numCandidates={candidates.length}>
      {candidates.map((candidate) => (
        <ListItem
          width={isMobile ? '100%' : undefined}
          key={getCandidateKey(candidate)}
        >
          <CandidateCardWrapper
            positionName={
              positions && getPositionName(String(candidate.position_id))
            }
          >
            {isDesktop ? (
              <CandidateCard
                key={getCandidateKey(candidate)}
                candidate={candidate}
                defaultChecked={isDefaultChecked(candidate)}
                candidateBioPath={
                  candidateBioPath && `${candidateBioPath}/${candidate.id}`
                }
              />
            ) : (
              <MobileCandidateCard
                width="100%"
                candidate={{
                  id: candidate.id,
                  name: `${candidate.first_name} ${candidate.last_name}`,
                  party: candidate.party_name,
                  image: candidate.thumb_url || candidate?.photo_url,
                }}
                href={`/${candidateBioPath}/${candidate.id}`}
              />
            )}
          </CandidateCardWrapper>
        </ListItem>
      ))}
    </CandidateLayout>
  );
};
