import { extendTheme } from '@chakra-ui/react';

import foundations from './foundations';
import components from './components';
import { styles, customStyles } from './styles';

const theme = extendTheme({
  ...foundations,
  styles,
  ...customStyles,
  components,
});

export { theme };

export default theme;
