import { Center, Icon, Link, Text } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import React, { FC, ReactElement } from 'react';
import type { AlertProps } from '@chakra-ui/react';

import { Alert } from '../../components';
import { useIsMobile } from '../../hooks';
import { createGoogleMapsUrl, formatTimeAsMins } from '../../utils/helpers';

interface PollingPlaceProps extends AlertProps {
  name?: string;
  address?: string;
  maplinkAria?: string;
  hours?: string;
  displayOpen?: boolean;
}

export const PollingPlace: FC<PollingPlaceProps> = ({
  name = '',
  address = '',
  maplinkAria = '',
  hours = '',
  displayOpen = false,
  ...rest
}): ReactElement => {
  const isMobile = useIsMobile();

  const checkPollingLocationOpen = (hours: string) => {
    const hourTimes = hours.toLowerCase().trim().split('-');
    const startTime = formatTimeAsMins(hourTimes[0]);
    const endTime = formatTimeAsMins(hourTimes[1]);
    const currTime = new Date().getHours() * 60 + new Date().getMinutes();
    return currTime >= startTime && currTime <= endTime ? (
      <Text as="span">
        <Text as="span" textColor={'green'}>
          Open
        </Text>{' '}
        • Hours: {hours.trim()}
      </Text>
    ) : (
      <Text as="span">
        <Text as="span" textColor={'red'}>
          Closed
        </Text>{' '}
        • Hours: {hours.trim()}
      </Text>
    );
  };

  const titleParam: ReactElement = (
    <div style={{ fontWeight: 'normal' }}>
      <Text textStyle={'h4'}>{name}</Text>
      <Text textStyle={'h5'} fontWeight={'normal'}>
        {address}
      </Text>
      <Text as="span" textStyle={'h5'} fontWeight={'normal'}>
        {displayOpen
          ? checkPollingLocationOpen(hours)
          : `Hours: ${hours.trim()}`}
      </Text>
    </div>
  );

  return (
    <Center minWidth="100%">
      <Alert
        body={titleParam}
        mt={0}
        titleProps={{ flexGrow: 1 }}
        flexDir="row"
        alignItems="center"
        width="100%"
        variant="help"
        margin-left="10px"
        {...rest}
      >
        {address && (
          <Link
            href={createGoogleMapsUrl(address)}
            alignContent="end"
            display="flex"
            aria-label={maplinkAria}
            isExternal
            backgroundColor={'transparent'}
            width={['40px', '130px']}
            borderColor="transparent"
          >
            <Center>
              {isMobile || <Text>Open Map</Text>}
              <Icon as={ExternalLinkIcon} ml={1} />
            </Center>
          </Link>
        )}
      </Alert>
    </Center>
  );
};

export default PollingPlace;
