import { Center, Flex, Icon, Link, Text } from '@chakra-ui/react';
import React, { FC, ReactElement } from 'react';
import type { AlertProps } from '@chakra-ui/react';
import { PhoneIcon } from '@chakra-ui/icons';
import LinkIcon from '@mui/icons-material/Link';

import { Alert } from '..';
import { useIsMobile } from '../../hooks';

interface ResourceContactProps extends AlertProps {
  title?: string;
  website?: string;
  websiteAria?: string;
  phone?: string;
  phoneAria?: string;
}

export const ResourceContact: FC<ResourceContactProps> = ({
  title = '',
  website = '',
  websiteAria = '',
  phone = '',
  phoneAria = '',
  ...rest
}): ReactElement => {
  const isMobile = useIsMobile();

  return (
    <Center>
      <Alert
        title={title}
        mt={3}
        titleProps={{ flexGrow: 1 }}
        flexDir="row"
        alignItems="center"
        width="100%"
        variant="help"
        {...rest}
      >
        <Flex w="100%" ml="auto">
          {website && (
            <Link
              maxW="fit-content"
              href={website}
              alignContent="center"
              display="flex"
              aria-label={websiteAria}
              isExternal
            >
              <Center>
                <Icon as={LinkIcon} mr={1} />
                {!isMobile && <Text>Website</Text>}
              </Center>
            </Link>
          )}

          {phone && (
            <Link
              ml={2}
              maxW="fit-content"
              href={`tel:${phone}`}
              alignContent="center"
              display="flex"
              aria-label={phoneAria}
            >
              <Center>
                <PhoneIcon mr={2} aria-hidden="true" />
                {!isMobile && <Text>Call</Text>}
              </Center>
            </Link>
          )}
        </Flex>
      </Alert>
    </Center>
  );
};
