import React, { FC, ReactElement } from 'react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Text, Link, VStack } from '@chakra-ui/react';
import type { AccordionProps, AccordionButtonProps } from '@chakra-ui/react';

import type { Endorsement } from '../../types/ballotReady';
import { InfoAccordion } from '../ui/InfoAccordion';
interface EndorsementsAccordionProps extends AccordionProps {
  endorsements: Endorsement[];
  headerText: string;
  buttonProps: AccordionButtonProps;
}

export const EndorsementsAccordion: FC<EndorsementsAccordionProps> = ({
  endorsements,
  headerText,
  buttonProps,
  ...rest
}): ReactElement => (
  <InfoAccordion
    headerText={headerText}
    panelText={
      <VStack alignItems="start" spacing={6}>
        {endorsements.map((endorsement, index) => (
          <div key={index}>
            <Link
              variant="inline"
              href={endorsement?.website_url ?? ''}
              isExternal
            >
              {endorsement.name}
              {<ExternalLinkIcon mx="5px" />}
            </Link>
            <Text textStyle={'h6'} flex="1" textAlign="left">
              {endorsement.description}
            </Text>
          </div>
        ))}
      </VStack>
    }
    buttonProps={buttonProps}
    {...rest}
  />
);

export default EndorsementsAccordion;
