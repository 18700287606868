import React, { FC, Fragment } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import pluralize from 'pluralize';

import { ElectionLabel } from '../label/ElectionLabel';

interface BallotListWrapperProps {
  length: number;
  countDescriptor: 'categories' | 'candidates' | 'races';
  includeElectionLabel?: boolean;
  electionId?: number;
}

const BallotListWrapper: FC<BallotListWrapperProps> = ({
  children,
  length,
  includeElectionLabel,
  electionId,
  countDescriptor,
}) => (
  <Fragment>
    <Flex
      w="60%"
      flexDir="column"
      alignContent="flex-start"
      justify="flex-start"
    >
      {includeElectionLabel && <ElectionLabel electionId={electionId} />}
      <Text mt="4px" mb="15px" color="grey">
        {`${pluralize(countDescriptor, length, true)}`}
      </Text>
    </Flex>
    {children}
  </Fragment>
);

export default BallotListWrapper;
