/* eslint-disable no-magic-numbers */
import {
  Box,
  Center,
  Icon,
  Image,
  Link,
  Text,
  useColorMode,
  VStack,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import type { BoxProps } from '@chakra-ui/react';
import React, { FC, ReactElement } from 'react';
import { ChevronRight } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

import { Candidate } from '../../types/ballotReady';
import { FavoriteButton } from '..';
import { PartyTag } from '../tag/PartyTag';
import emptyProfilePic from '../../assets/pfp.png';

interface CandidateCardProps extends BoxProps {
  candidate: Candidate;
  candidateBioPath?: string;
}

export const CandidateCard: FC<CandidateCardProps> = ({
  candidate,
  candidateBioPath,
}): ReactElement => {
  const modeProps = useColorMode();

  const pathname = String(
    `${useLocation().pathname.replace(/^\//, '')}/candidate/${candidate.id}`,
  );

  const path = candidateBioPath ?? pathname;
  const electionId = Number(path.match(/\/ballot\/(\d+)\/.*/)?.at(1));
  const level = path.match(/\/ballot\/\d+\/(\w+)/)?.at(1);
  const positionName = path.match(/\/ballot\/\d+\/.*\/(.*)/)?.at(1);

  return (
    <VStack
      borderRadius="5px"
      w="240px"
      h="315px"
      bgColor={mode('brand.lightGray', 'black')(modeProps)}
      position="relative"
      zIndex="base"
      tabIndex={0}
      border="1px solid"
      borderColor={'brand.midLightGrey'}
      boxShadow="md"
    >
      <FavoriteButton
        candidate={candidate}
        positionName={positionName}
        level={level}
        electionId={String(electionId)}
        position="absolute"
        right="10px"
        top="10px"
      />
      <VStack maxW="100%" h="275px">
        <Box w="100%">
          <Image
            borderRadius="50%"
            h="150px"
            w="150px"
            bgColor="brand.base"
            margin="25px auto 0 auto"
            src={candidate.photo_url || emptyProfilePic}
            objectFit="cover"
          />
        </Box>
        <Center w="100%" flexDir="column">
          <Text
            w="90%"
            isTruncated
            textAlign="center"
            as="h4"
            textStyle={'h4'}
            mt={2}
          >
            {`${candidate.first_name} ${candidate.last_name}`}
          </Text>
          <PartyTag party={candidate.party_name} />
        </Center>
      </VStack>

      <Center
        role="group"
        as={Link}
        cursor="pointer"
        position="absolute"
        borderRadius="0px 0px 5px 5px"
        _hover={{ textDecor: 'none' }}
        bottom="0"
        h="40px"
        w="100%"
        color={mode('white', 'brand.yellow')(modeProps)}
        backgroundColor={mode('brand.primary', 'black')(modeProps)}
        href={`/${path}`}
        border="2px"
        borderColor={mode('brand.primary', 'brand.yellow')(modeProps)}
        aria-label="View candidate bio"
        transition="transform 300ms ease-out"
        fontWeight="bold"
      >
        <Text>View Candidate</Text>
        <Icon
          as={ChevronRight}
          transition="transform 250ms ease-out"
          _groupHover={{
            transition: 'transform 250ms ease-in',
            transform: 'translateX(10px)',
          }}
        />
      </Center>
    </VStack>
  );
};

export default CandidateCard;
