import { Breadcrumb } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { useBallotData } from '../../contexts';
import { capitalize, isNumber } from '../../utils/helpers';

import { BreadcrumbItem } from './BreadcrumbItem';

const isValidBreadcrumb = (pathname: string): boolean =>
  pathname !== 'home' && pathname !== '';

export const BallotBreadcrumbs = () => {
  const { pathname } = useLocation();
  const { elections, candidates } = useBallotData();

  const pathnames = pathname.split('/').filter(isValidBreadcrumb);
  const candidateIndex = pathnames.indexOf('candidates');

  if (elections.length === 0) {
    return null;
  }

  const breadcrumbs = pathnames.map((path, index) => {
    const href = `/home/${pathnames.slice(0, index + 1).join('/')}`;
    const isCurrentPage = index === pathnames.length - 1;

    if (
      (index !== candidateIndex + 1 && isNumber(path)) ||
      path === 'candidate'
    ) {
      return null;
    }

    let breadcrumb = capitalize(decodeURIComponent(path));

    if (index === candidateIndex + 1 && isNumber(path)) {
      const candidate = candidates[path];

      if (!candidate) {
        breadcrumb = 'Unknown Candidate';
      } else {
        breadcrumb = `${candidate.first_name} ${candidate.last_name}`;
      }
    }

    return (
      <BreadcrumbItem
        key={path}
        route={href}
        isCurrentPage={isCurrentPage}
        text={breadcrumb}
      />
    );
  });

  return (
    <Breadcrumb separator={<ChevronRightIcon w={5} h={5} />}>
      {breadcrumbs}
    </Breadcrumb>
  );
};
