import { State, FavoriteCandidate } from '../contexts/FavCandidatesContext';

export interface Action {
  type: 'add-favorite' | 'remove-favorite';
  favorite: FavoriteCandidate;
}

export const favCandidatesReducer = (favCandidates: State, action: Action) => {
  switch (action.type) {
    case 'add-favorite':
      favCandidates = [
        ...favCandidates,
        {
          id: action.favorite.id,
          name: action.favorite.name,
          position_id: action.favorite.position_id,
          election_id: action.favorite.election_id,
          level: action.favorite.level,
          positionName: action.favorite.positionName,
        },
      ];

      return favCandidates;
    case 'remove-favorite':
      favCandidates = favCandidates.filter(
        (el) =>
          el.id !== action.favorite.id ||
          el.position_id !== action.favorite.position_id ||
          el.name !== action.favorite.name,
      );

      return favCandidates;
    default:
      throw Error('Invalid action');
  }
};
