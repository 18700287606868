import { BrinkColors } from './colors';
import { sizes } from './sizes';
import { BrinkTypography } from './typography';

export const foundations = {
  colors: BrinkColors,
  ...BrinkTypography,
  sizes,
};

export default foundations;
