import React, { FC } from 'react';
import type { LinkProps } from '@chakra-ui/react';
import { useColorMode, Link } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface BackLinkProps extends LinkProps {
  href: string;
  text: string;
  isRightLink?: boolean;
}

export const BackLink: FC<BackLinkProps> = ({
  href,
  isRightLink = false,
  text = 'Back',
  ...rest
}) => {
  const modeProps = useColorMode();
  const blue = mode('blue', 'brand.yellow')(modeProps);
  const getBorderColor = () => {
    if (modeProps.colorMode === 'dark') {
      return 'brand.yellow';
    }
  };
  return (
    <Link
      position="relative"
      fontWeight="bold"
      color={blue}
      border={mode('none', '1px solid')(modeProps)}
      borderColor={getBorderColor()}
      left={['3', '10']}
      top={['4', '5']}
      href={href}
      padding="5px"
      background="none"
      {...rest}
    >
      {isRightLink ? (
        <>
          {text} <ChevronRightIcon w={4} h={4} mr={2} mb={1} />
        </>
      ) : (
        <>
          <ChevronLeftIcon w={4} h={4} mr={2} mb={1} /> {text}
        </>
      )}
    </Link>
  );
};
