import { Box, Text } from '@chakra-ui/react';
import React, { FC, ReactElement } from 'react';

interface CandidateCardWrapperProps {
  positionName?: string | undefined;
}
export const CandidateCardWrapper: FC<CandidateCardWrapperProps> = ({
  children,
  positionName,
}): ReactElement => (
  <Box>
    {positionName && (
      <Text mb="3px" size="23px">
        {positionName}
      </Text>
    )}
    {children}
  </Box>
);
