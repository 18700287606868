import React, { ReactElement } from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {
  chakra,
  UnorderedList,
  Icon,
  Flex,
  Text,
  useColorMode,
  Link,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { ExternalLinkIcon } from '@chakra-ui/icons';

export const MobileFooter = (): ReactElement => {
  const icons = [
    {
      icon: TwitterIcon,
      link: 'https://twitter.com/BrinkVoting',
    },
    {
      icon: FacebookIcon,
      link: 'https://www.facebook.com/brinkelectionguide',
    },
    {
      icon: YouTubeIcon,
      link: 'https://www.youtube.com/channel/UC1vQ1pxG6RH_ook517newHQ',
    },
  ];

  const modeProps = useColorMode();

  return (
    <chakra.footer
      top="0"
      zIndex="overlay"
      aria-label="footer"
      backgroundColor={mode('brand.primary', 'black')(modeProps)}
      borderColor={mode('brand.lightBase', 'black')(modeProps)}
    >
      <UnorderedList
        marginLeft="0"
        listStyleType="none"
        alignItems="center"
        height="navHeight"
        display="flex"
        paddingTop="20px"
      >
        <Flex alignItems="center" justifyContent="space-evenly" width="100%">
          {icons.map((iconPair, index) => (
            <chakra.a href={iconPair.link} key={index} target="_blank">
              <Icon
                as={iconPair.icon}
                bg={mode('brand.primary', 'black')(modeProps)}
                color={mode('white', 'brand.yellow')(modeProps)}
                width="35px"
                height="35px"
                key={index}
              />
            </chakra.a>
          ))}
        </Flex>
      </UnorderedList>
      <Flex
        alignItems="center"
        justifyContent="center"
        width="100%"
        backgroundColor={mode('brand.primary', 'black')(modeProps)}
        height="55px"
        paddingBottom="10px"
      >
        <Link
          href="https://www.brinkapp.co/"
          textAlign="center"
          color="white"
          marginRight="10px"
          backgroundColor={mode('brand.primary', 'black')(modeProps)}
          border="none"
          target="_blank"
        >
          <Flex alignItems="center" justifyContent="space-evenly" width="185px">
            <Text>Download Mobile App</Text>
            <Icon
              as={ExternalLinkIcon}
              w={4}
              h={4}
              mb={1}
              color="white"
              marginLeft="0px"
            />
          </Flex>
        </Link>
      </Flex>
    </chakra.footer>
  );
};

export default MobileFooter;
