import { mode } from '@chakra-ui/theme-tools';
import type { StyleConfig } from '@chakra-ui/theme-tools';

export const BreadcrumbStyles: StyleConfig = {
  baseStyle: (props) => ({
    link: {
      color: mode('brand.blue', 'brand.yellow')(props),
      fontWeight: 700,
    },
    separator: {
      color: mode('brand.blue', 'white')(props),
    },
  }),
};
