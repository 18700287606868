import { init, track } from '@amplitude/analytics-browser';

init(process.env.REACT_APP_AMPLITUDE_KEY);

interface PageViewInfo {
  pageLocation?: string;
  pagePath?: string;
  pageTitle?: string;
}

export const logPageView = (pageInfo: PageViewInfo) => {
  try {
    track('Page Viewed', pageInfo);
  } catch (error) {
    console.error(error);
  }
};
