import React, { FC, ReactElement } from 'react';
import { Box, Flex, IconButton, Stack, useColorMode } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { CloseIcon } from '@chakra-ui/icons';

import { AccessibilityForm } from '../components';

interface MobileNavOverlayProps {
  isOpen: boolean;
  onClose: () => void;
}

export const MobileNavOverlay: FC<MobileNavOverlayProps> = ({
  isOpen,
  onClose,
}): ReactElement => {
  const modeProps = useColorMode();

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      width="100vw"
      height="100vh"
      zIndex="overlay"
      backgroundColor={mode('brand.lightBase', 'black')(modeProps)}
      display={isOpen ? 'block' : 'none'}
    >
      <Flex justify={'flex-end'}>
        <IconButton
          mt={5}
          mr={5}
          onClick={onClose}
          color="grey"
          aria-label="Close Mobile Navigation"
          icon={<CloseIcon />}
          bg="transparent"
          fontSize="18px"
        />
      </Flex>
      <Box mx="auto" maxWidth="85%">
        <Stack spacing="30px">
          <AccessibilityForm />
        </Stack>
      </Box>
    </Box>
  );
};
