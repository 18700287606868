import { mode } from '@chakra-ui/theme-tools';
import type { StyleConfig } from '@chakra-ui/theme-tools';

export const ButtonStyles: StyleConfig = {
  variants: {
    back: (props) => ({
      bg: 'transparent',
      fontWeight: 'bold',
      borderColor: mode('none', 'brand.yellow')(props),
      border: mode('0px', '1px')(props),
      color: mode('brand.blue', 'brand.yellow')(props),
      _hover: {
        bg: mode('transparent', 'brand.hover.yellow'),
      },
    }),
    accent: (props) => ({
      color: mode('white', 'black')(props),
      _hover: {
        color: 'white',
        bg: mode('brand.blue', 'brand.yellow')(props),
        textDecoration: 'none',
        _disabled: {
          bg: mode('brand.blue', 'brand.yellow')(props),
        },
      },
      _active: { bg: mode('brand.blue', 'brand.yellow')(props) },
      backgroundColor: mode('brand.blue', 'brand.yellow')(props),
      fontWeight: 'normal',
      textDecoration: 'none',
    }),
    alt: (props) => ({
      border: '1px solid',
      borderColor: mode('brand.blue', 'brand.yellow')(props),
      color: mode('brand.blue', 'brand.yellow')(props),
      borderRadius: '5px',
      backgroundColor: mode('brand.white', 'brand.black')(props),
      _hover: {
        backgroundColor: mode('brand.hover.blue', 'brand.hover.yellow')(props),
        textDecor: 'none',
      },
    }),
  },
};
