import React, { ReactElement } from 'react';
import { Box, Text, useColorMode, useTheme } from '@chakra-ui/react';

import { SearchAddress } from '../components';
import { Page } from '../layout';

export const LandingPage = (): ReactElement => {
  const { colorMode } = useColorMode();
  const { sizes } = useTheme();

  return (
    <Page
      title="Landing"
      ariaLabel="enter address landing page"
      background={colorMode === 'light' ? 'brand.lightBase' : 'black'}
      backgroundColor={colorMode === 'light' ? 'brand.lightBase' : 'black'}
      height={`calc(100vh - ${sizes.navHeight})`}
      spacing="0px"
      alignItems="center"
      justifyContent="center"
      mainProps={{ mt: '0px' }}
      w="100%"
    >
      <Box mt={`-${sizes.navHeight}`} width={['90%', '70%', '60%']}>
        <Text as="h1" textStyle="h1" paddingBottom="3">
          Voting made easy
        </Text>
        <label htmlFor="address-autocomplete">
          <Text fontWeight="normal" as="h2" textStyle="h2" marginBottom="20px">
            Enter your address to find your voting checklist and ballot
          </Text>
        </label>
        <SearchAddress id="address-autocomplete" />

        <Text color="brand.grey" mt={2}>
          Your data is yours! Your address and favorited candidates are not
          stored on Brink’s servers.
        </Text>
      </Box>
    </Page>
  );
};

export default LandingPage;
