import { Box, Flex, Text, useColorMode } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import React from 'react';

interface TitleProps {
  name: string;
  subheading?: string;
  date?: string;
  display?: 'center';
}

const BallotTitle = ({ name, date, display, subheading }: TitleProps) => {
  const modeProps = useColorMode();

  return (
    <Flex
      pt="10px"
      pb="10px"
      flexDir="column"
      align={display === 'center' ? 'center' : 'flex-start'}
      // width="100ch"
    >
      <Box width="100%">
        <Text as="h2" textStyle={'h2'} mt={3}>
          {name}
        </Text>
        {date && <Text>{date}</Text>}
        {subheading && (
          <Text mt="6px" color={mode('brand.gray', 'white')(modeProps)}>
            {subheading}
          </Text>
        )}
      </Box>
    </Flex>
  );
};

export default BallotTitle;
