import { Box, Center, Flex, Spinner, useTheme } from '@chakra-ui/react';
import React, { ReactElement, useEffect } from 'react';
import type { ContainerProps } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { BreadcrumbBar, ElectionLabel } from '../../components';
import { useSavedAddress } from '../../contexts';
import { useIsDesktop } from '../../hooks';
interface BallotContainerProps extends ContainerProps {
  includeElectionLabel?: boolean;
  electionId?: number;
  hideBreadcrumbBar?: boolean;
  isLoading?: boolean;
}

export const BallotContainerPage: React.FC<BallotContainerProps> = ({
  children,
  includeElectionLabel,
  electionId,
  hideBreadcrumbBar,
  isLoading,
  ...rest
}): ReactElement => {
  const navigate = useNavigate();
  const { address } = useSavedAddress();
  const isDesktop = useIsDesktop();
  const { sizes } = useTheme();

  useEffect(() => {
    if (!address) {
      navigate('/landing');
    }
  }, [navigate, address]);

  const getPageHeight = (): string => {
    const tabHeight = isDesktop ? sizes.tabHeight : sizes.tabHeightMobile;

    return `calc(100vh - ${sizes.navHeight} - ${tabHeight} - ${sizes.navHeight})`;
  };

  return (
    <Box minH={getPageHeight()}>
      {!hideBreadcrumbBar && <BreadcrumbBar />}
      {includeElectionLabel && (
        <Flex w="100%" padding="25px 0 20px 20px" h="30px">
          <ElectionLabel electionId={electionId} />
        </Flex>
      )}

      <Box
        margin="auto"
        marginTop={['5px', '15px', '50px']}
        width={['90%', '75%', '70%', '55%']}
        {...rest}
      >
        {isLoading ? (
          <Center h="60vh">
            <Spinner size="xl" />
          </Center>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};

export default BallotContainerPage;
