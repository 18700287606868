import React, { FC, ReactElement } from 'react';
import type { AlertProps } from '@chakra-ui/react';

import type { Election } from '../../types/ballotReady';
import { Alert } from '../ui/Alert';
import { formatDate } from '../../utils/helpers';

interface ElectionCardProps extends AlertProps {
  election: Election;
}

export const ElectionCard: FC<ElectionCardProps> = ({
  election,
  ...rest
}): ReactElement => (
  <Alert title={election.name} height="87px" {...rest}>
    {formatDate(election.election_day)}
  </Alert>
);
