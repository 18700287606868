import { Text, VStack } from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';

import { Contentful } from '../api';
import { FAQAccordion } from '../components';
import { Disclaimer } from '../components/ui/Disclaimer';
import { Page } from '../layout';
import { HelpContent } from '../types/contentful';

export const VoterFAQPage = (): ReactElement => {
  const [helpContents, setHelpContents] = useState<HelpContent[]>([]);

  useEffect(() => {
    document.title = 'FAQ | Brink';
  }, []);

  useEffect(() => {
    // TODO: How do we indicate loading states for the API in a way that is accessible?
    const loadHelpContent = async (): Promise<void> => {
      const res = await Contentful.getHelpContent('faq');
      // TODO: Should it throw an error if it doesn't work when trying to get FAQ information?
      setHelpContents(res);
    };
    loadHelpContent();
  }, []);

  return (
    <>
      <Page title="FAQ" ariaLabel="frequently asked questions page">
        <Text as="h1" textStyle="h1">
          Frequently Asked Questions
        </Text>
        <VStack spacing="13px" mb="20px">
          {helpContents.map((helpContent) => (
            <FAQAccordion
              headerText={helpContent.title}
              panelText={helpContent.body}
              key={helpContent.title}
              buttonProps={{ textStyle: 'h4' }}
            />
          ))}
        </VStack>
        <Disclaimer
          heading="Don't see your question or issue?"
          description="Check out the Help page for more resources that you can use to get nearby help or resolve polling place issues!"
          hyperlink="/home/help"
        />
      </Page>
    </>
  );
};
export default VoterFAQPage;
