import { mode } from '@chakra-ui/theme-tools';
import type { StyleConfig } from '@chakra-ui/theme-tools';

export const TextStyles: StyleConfig = {
  variants: {
    tagInfo: (props: any) => ({
      borderLeft: '4px',
      borderColor: mode('brand.primary', 'brand.yellow')(props),
      paddingLeft: '6px',
    }),
    tagAlert: {
      borderLeft: '4px',
      borderColor: 'red',
      paddingLeft: '6px',
    },
    markdownLabel: (props) => ({
      borderLeft: '4px',
      borderColor: mode('brand.primary', 'brand.yellow')(props),
      paddingLeft: '6px',
      color: mode('brand.primary', 'brand.yellow')(props),
    }),
  },
};
