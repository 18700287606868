import React, { ReactElement, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { capitalize } from '../../utils/helpers';
import BallotContent from '../../components/ballot/BallotContent';
import BallotTitle from '../../components/ballot/BallotTitle';
import { useBallotData } from '../../contexts';

import BallotContainer from './BallotContainerPage';

export const PositionsPage = (): ReactElement => {
  const { pathname } = useLocation();
  const electionType = pathname.substring(pathname.lastIndexOf('/') + 1);
  const { positions: ballotPositions, isLoading } = useBallotData();
  const electionId = Number(
    window.location.href.match(/\/ballot\/(\d+)\/.*/)?.[1],
  );

  useEffect(() => {
    document.title = `${capitalize(electionType)} Races | Brink`;
  }, [electionType]);

  const correspondingPosition = useMemo(
    () =>
      !isLoading
        ? ballotPositions[electionId]?.filter(
            (position) => position.level === electionType.toUpperCase(),
          )
        : [],
    [isLoading, ballotPositions, electionId, electionType],
  );

  return (
    <BallotContainer includeElectionLabel isLoading={isLoading}>
      {!isLoading && ballotPositions && (
        <>
          <BallotTitle name={capitalize(electionType)} />
          <BallotContent
            link={`${pathname}/`}
            positions={correspondingPosition}
            attribute="name"
            countDescriptor="races"
          />
        </>
      )}
    </BallotContainer>
  );
};

export default PositionsPage;
