import type { StyleConfig } from '@chakra-ui/theme-tools';

export const TabStyles: StyleConfig = {
  variants: {
    line: {
      tab: {
        borderBottom: '5px solid',
        color: 'grey',
        _selected: {
          color: 'blue',
        },
        margin: 0,
        width: '200px',
        fontWeight: 'bold',
      },
      tablist: {
        borderColor: 'grey',
        borderBottom: '1px solid',
        justifyContent: 'center',
        bg: 'baseLight',
      },
    },
  },
};
