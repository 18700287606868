import React, { ReactElement, useState } from 'react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';

import { MobileNavOverlay } from '../../layout/MobileNavOverlay';

import { NavItem } from './NavItem';

export const NavTabletAndMobileMenu = (): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <NavItem variant="default" position={'relative'}>
      <IconButton
        aria-label="Open Mobile Navigation"
        icon={<HamburgerIcon />}
        onClick={() => setIsOpen(true)}
        bg="transparent"
        fontSize="18px"
      />
      <MobileNavOverlay isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </NavItem>
  );
};
