import { List, ListItem, Text } from '@chakra-ui/react';
import React, { Fragment, ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Alert } from '../../components';
import BallotContent from '../../components/ballot/BallotContent';
import BallotTitle from '../../components/ballot/BallotTitle';
import { Disclaimer } from '../../components/ui/Disclaimer';
import { useBallotData } from '../../contexts';
import { Position } from '../../types/ballotReady';
import { formatDate } from '../../utils/helpers';

import BallotContainer from './BallotContainerPage';

export const sortOrder: Record<string, number> = {
  FEDERAL: 0,
  STATE: 1,
  COUNTY: 2,
  CITY: 3,
  LOCAL: 4,
  PARTY: 5,
};

export const RacesPage = (): ReactElement => {
  const { pathname } = useLocation();
  const { positions: ballotPositions, elections, isLoading } = useBallotData();

  useEffect(() => {
    document.title = 'Ballot | Brink';
  }, []);

  const getSortedLevels = (positions: Position[] | undefined): Position[] => {
    const sortedPositions = positions
      ? positions.sort((a, b) => sortOrder[a.level] - sortOrder[b.level])
      : [];
    const sortedLevels = sortedPositions.filter(
      (position, index, self) =>
        index === self.findIndex((t) => t.level === position.level),
    );
    return sortedLevels;
  };

  return (
    <>
      <BallotContainer isLoading={isLoading}>
        {elections.length && ballotPositions ? (
          <Fragment>
            <Text alignItems="flex-start" as="h1" textStyle={'h1'}>
              Elections
            </Text>
            <List>
              {elections.map((election) => (
                <ListItem key={election.id}>
                  <BallotTitle
                    name={election.name}
                    date={formatDate(election.election_day)}
                  />
                  <BallotContent
                    link={`${pathname}/${election.id}/`}
                    positions={getSortedLevels(ballotPositions[election.id])}
                    attribute="level"
                    countDescriptor="categories"
                    linkWidth="100%"
                  />
                </ListItem>
              ))}
            </List>
          </Fragment>
        ) : (
          <Alert title="No current elections">
            We will update the candidates in your area when the next cycle
            begins
          </Alert>
        )}
        <Disclaimer
          heading="Don't see your election?"
          description="We use Ballot Ready to retrieve your election data. If you notice
        missing races, positions, candidate lists, or candidate information,
        it is likely a limitation in the Ballot Ready data set. To learn more,
        please visit your Secretary of State or board of election website"
        />
      </BallotContainer>
    </>
  );
};

export default RacesPage;
