import React, { FC, ReactElement } from 'react';
import type { AccordionProps, AccordionButtonProps } from '@chakra-ui/react';

import { InfoAccordion } from '../ui/InfoAccordion';
interface FAQAccordionProps extends AccordionProps {
  headerText: string;
  panelText: string;
  buttonProps: AccordionButtonProps;
}

export const FAQAccordion: FC<FAQAccordionProps> = ({
  headerText,
  panelText,
  buttonProps,
  ...rest
}): ReactElement => {
  const getFormattedPanelText = () => {
    let formattedText = '';
    panelText.split('\\n').map((text) => {
      formattedText += `${text}` + '\n';
    });
    return formattedText;
  };

  return (
    <InfoAccordion
      headerText={headerText}
      panelText={getFormattedPanelText()}
      buttonProps={buttonProps}
      {...rest}
    />
  );
};

export default FAQAccordion;
