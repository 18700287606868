import { mode } from '@chakra-ui/theme-tools';
import type { StyleConfig } from '@chakra-ui/theme-tools';

const baseLink = {
  padding: '0px',
  backgroundColor: 'none',
  border: 'none',
  borderRadius: '0px',
};

export const LinkStyles: StyleConfig = {
  baseStyle: (props) => ({
    padding: '10px 15px',
    backgroundColor: mode('brand.base', 'black')(props),
    color: mode('brand.primary', 'brand.yellow')(props),
    border: '1px',
    borderColor: mode('brand.darkBase', 'brand.yellow')(props),
    borderRadius: '5px',
    _hover: {
      textDecoration: 'underline',
    },
  }),
  variants: {
    inline: (props) => ({
      ...baseLink,
      color: mode('brand.blue', 'brand.yellow')(props),
      _hover: {
        color: mode('brand.blue', 'brand.yellow')(props),
      },
    }),
    darkInline: (props) => ({
      ...baseLink,
      color: mode('brand.primary', 'brand.yellow')(props),
      _hover: {
        color: mode('brand.primary', 'brand.yellow')(props),
      },
    }),
    ballot: (props) => ({
      ...baseLink,
      height: 'max-content',
      backgroundColor: mode('brand.lightGray', 'black')(props),
      alignItems: 'center',
      padding: 6,
      color: 'brand.primary',
      fontWeight: 'bold',
      borderRadius: 5,
      border: '1px solid',
      borderColor: mode('brand.midLightGray', 'brand.yellow')(props),
      boxShadow: 'sm',
      _hover: {
        backgroundColor: mode(
          'brand.midLightGray',
          'brand.hover.yellow',
        )(props),
      },
    }),
  },
};
