import React, {
  createContext,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import { CheckItem } from '../constants/checkItems';
import { ChecklistAction, checklistReducer } from '../reducers';

export const CHECKITEM_KEY = 'completedRegistrationItems';

export type ChecklistState = CheckItem[];

const checkedItems: ChecklistState = [];

// TODO: Found out way to not disable eslint
// eslint-disable-next-line no-unused-vars
export type ChangeCheckedItems = (action: ChecklistAction) => void;

interface ChecklistInterface {
  storedItems: ChecklistState;
  dispatch: ChangeCheckedItems;
}

const ChecklistContext = createContext<ChecklistInterface | undefined>(
  undefined,
);

const initializer = (): ChecklistState => {
  const localData = localStorage.getItem(CHECKITEM_KEY);
  return localData ? JSON.parse(localData) : [];
};

const ChecklistContextProvider: FC = ({ children }): ReactElement => {
  const [storedItems, dispatch] = useReducer(
    checklistReducer,
    checkedItems,
    initializer,
  );
  useEffect(() => {
    localStorage.setItem(CHECKITEM_KEY, JSON.stringify(storedItems));
  }, [storedItems]);

  return (
    <ChecklistContext.Provider value={{ storedItems, dispatch }}>
      {children}
    </ChecklistContext.Provider>
  );
};

export default ChecklistContextProvider;

export const useChecklist = () => {
  const context = useContext(ChecklistContext);

  if (!context) {
    throw new Error(
      'useChecklist must be used inside the ChecklistContext provider',
    );
  }

  return context;
};
