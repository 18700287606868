import { VotingMethodType } from '../types/contentful';

export interface CheckItemContent {
  title: string;
  content: string;
  alert: string | null;
  buttonLabel: string | null;
  missing?: string;
}

export type CheckItem = keyof typeof checkItems;

export const checkItems = {
  'check-reg-status': {
    title: 'Check voter registration status',
    content:
      'Many states often purge voters from their records. It’s best practice to double-check your registration status well in-advance of the election.',
    alert:
      'Don’t be caught off guard at a polling place! Double check your voter registration status.',
    buttonLabel: 'Check my voter registration', //link to this button comes from election authority, "polling-place-url" or "voter-registration-status-url"
  },
  'reg-to-vote': {
    title: 'Register to vote',
    content: 'If you’re not registered yet, take two minutes to register here!',
    alert:
      'Don’t forget to register to vote! If you’ve already done this or confirmed you’re registered to vote, check off this todo item. ',
    buttonLabel: 'Register to vote', //link comes from "homepage-url" lol
  },
  'find-poll-loc': {
    title: 'Find your polling location',
    content:
      'See where your polling location is so you can plan when and how to get there.',
    alert:
      "Don't forget to find your polling location and plan how to get there!",
    buttonLabel: 'Find my polling place',
  },
  'rev-vot-rights': {
    title: 'Review your voting rights',
    content:
      'Familiarize yourself with your voting rights to advocate for yourself and others if any problems arise.',
    alert: null,
    buttonLabel: 'Learn more',
  },
  'check-id-reqs': {
    title: 'Check ID requirements',
    content:
      "Most states require some identification to vote. Check your state's requirements today so you can be prepared.",
    missing:
      'We do not currently have information about ID requirements for your state. Please check with your local election authority for this information before you vote.',
    alert:
      "Many states will prevent you from voting if you do not have proper identification. Don't forget to check the requirements.",
    buttonLabel: 'View ID Requirements',
  },
  'go-to-poll': {
    title: 'Go to your polling location',
    content: 'It is time to go to your polling location to cast your vote!',
    alert:
      "Don't forget to find your polling location and plan how to get there!",
    buttonLabel: 'Find my polling location',
  },
  'use-vot-script': {
    title: 'Use the voting rights script',
    content:
      'Use this script to advocate for yourself if you face issues or discrimination while voting.',
    alert: null,
    buttonLabel: 'Learn more',
  },
  'choose-poll-loc': {
    title: 'Find your polling location',
    content:
      'See where your polling location is so you can plan when and how to get there.',
    alert:
      'Don’t forget to find your polling location and plan how to get there!',
    buttonLabel: 'Find my polling location',
  },
  'go-to-early-poll': {
    title: 'Go to your polling location',
    content: 'It is time to go to your polling location to cast your vote!',
    alert:
      "Don't forget to find your polling location and plan how to get there!",
    buttonLabel: 'Find my polling location',
  },
  'apply-mail-ballot': {
    title: 'Apply for your ballot online',
    content:
      'You’ll need your address and email to apply for mail-in voting. Do this as soon as possible because of potential USPS delays.',
    alert:
      "If you haven't applied for your mail-in ballot, please do so immediately. Otherwise your ballot might not arrive in time and you'll need to vote by a different method.",
    buttonLabel: 'Apply to Vote-by-Mail',
  },
  'mail-ballot-arrival': {
    title: 'Ensure your ballot has arrived',
    content: 'Be on the lookout to see if your ballot has come in yet.',
    alert: null,
    buttonLabel: null,
  },
  'mail-ballot': {
    title: 'Mail in your ballot',
    content:
      'With potential USPS delays, fill out and mail in your ballot right after receiving it. Double check you have properly signed and addressed.',
    alert:
      "If you haven't mailed in your ballot yet, depending on your state, you will need to drop it off at drop box, election office or at a polling station.",
    buttonLabel: 'Learn more',
  },
  'share-experience': {
    title: 'Tell us about your experience',
    content:
      'Share with Brink how your voting experience was so we know how to better support you.',
    alert: null,
    buttonLabel: null,
  },
  'learn-about-prov': {
    title: 'Learn about provisional ballots',
    content:
      'Provisional ballots allow you to register and vote at your polling location. Learn more.',
    alert: null,
    buttonLabel: null,
  },
  notifications: {
    title: 'Review notification settings',
    content: 'Avoid missing important deadlines by allowing us to remind you',
    alert: null,
    buttonLabel: null,
  },
  'research-candidates': {
    title: 'Research candidates',
    content:
      'Learn about candidates and keep track of who you want to vote for using the app’s Ballot functionality',
    alert: null,
    buttonLabel: 'View candidates',
  },
  'update-address': {
    title: 'If you have moved, update your address.',
    content:
      'You can update your address in settings to make sure that you have the correct information for upcoming elections and to locate your polling locations.',
    alert: null,
    buttonLabel: null,
  },
  'enable-notifications': {
    title: 'Enable notifications',
    content:
      'Enable notifications so you can always be up to date on upcoming elections and important dates.',
    alert: null,
    buttonLabel: 'Enable',
  },
  'ooc-check-reg-status': {
    title: 'Check voter registration status',
    content:
      'Many states often purge voters from their records. It’s best practice to double-check your registration status well in-advance of the election.',
    alert:
      'Don’t be caught off guard at a polling place! Double check your voter registration status.',
    buttonLabel: 'Check my voter registration',
  },
  'ooc-reg-to-vote': {
    title: 'Register to vote',
    content: 'If you’re not registered yet, take two minutes to register here!',
    alert:
      'Don’t forget to register to vote! If you’ve already done this or confirmed you’re registered to vote, check off this todo item. ',
    buttonLabel: 'Register to vote',
  },
};

//these match VotingMethodType but i am not sure about how to make that clear
//in the mobile repo their VotingMethodType is an enum
export type TodoInterface = Record<VotingMethodType, ElectionTodos[]>;

export const todos: TodoInterface = {
  'in-person': [
    { key: 'register', data: ['check-reg-status', 'reg-to-vote'] },
    {
      key: 'prepare',
      data: ['find-poll-loc', 'rev-vot-rights', 'research-candidates'],
    },
    { key: 'vote', data: ['check-id-reqs', 'go-to-poll', 'use-vot-script'] },
    { key: 'reflect', data: [] },
  ],
  'early-voting': [
    { key: 'register', data: ['check-reg-status', 'reg-to-vote'] },
    {
      key: 'prepare',
      data: ['choose-poll-loc', 'rev-vot-rights', 'research-candidates'],
    },
    {
      key: 'vote',
      data: ['check-id-reqs', 'go-to-early-poll', 'use-vot-script'],
    },
    { key: 'reflect', data: [] },
  ],
  'by-mail': [
    { key: 'register', data: ['check-reg-status', 'reg-to-vote'] },
    { key: 'apply', data: ['apply-mail-ballot'] },
    { key: 'prepare', data: ['research-candidates'] },
    { key: 'vote', data: ['mail-ballot'] },
    { key: 'reflect', data: [] },
  ],
  //no support for provisional in mobile repo
};

export interface ElectionTodos {
  key: string;
  data: CheckItem[];
}

export const OUT_OF_CYCLE_TODOS: ElectionTodos = {
  key: 'next-steps',
  data: ['update-address', 'ooc-check-reg-status', 'ooc-reg-to-vote'],
};

export const RECENT_ELECTION_TODO = {
  key: 'reflect',
  data: ['share-experience'],
};

export default todos;
