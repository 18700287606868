import React, { FC, ReactElement } from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import LinkIcon from '@mui/icons-material/Link';
import { Flex, Icon, Link, Text, useColorMode } from '@chakra-ui/react';
import type { LinkProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import type { CandidateUrl } from '../../types/ballotReady';
import { useIsMobile } from '../../hooks';

interface SocialLinkProps extends LinkProps {
  candidateUrl: CandidateUrl;
}

const urlPairs = {
  twitter: {
    icon: TwitterIcon,
    label: 'Twitter',
  },
  facebook: {
    icon: FacebookIcon,
    label: 'Facebook',
  },
  website: {
    icon: LinkIcon,
    label: 'Website',
  },
  instagram: {
    icon: InstagramIcon,
    label: 'Instagram',
  },
  volunteer: {
    icon: VolunteerActivismIcon,
    label: 'Volunteer',
  },
};

export const SocialLink: FC<SocialLinkProps> = ({
  candidateUrl,
  ...rest
}): ReactElement => {
  const isMobile = useIsMobile();

  const modeProps = useColorMode();

  return (
    <Link
      marginLeft={'10px'}
      padding={0}
      href={candidateUrl.url}
      isExternal
      {...rest}
    >
      <Flex
        direction="row"
        justify="flex-start"
        align="center"
        width={['55px', '165px']}
        height="55px"
        bg={mode('brand.lightGray', 'black')(modeProps)}
        border="1px"
        borderColor={mode('brand.midLightGrey', 'yellow')(modeProps)}
        borderRadius="5px"
      >
        <Icon
          as={urlPairs[candidateUrl.type]?.icon}
          bg={mode('brand.blue', 'black')(modeProps)}
          color={mode('white', 'brand.yellow')(modeProps)}
          width="35px"
          height="35px"
          marginLeft={isMobile ? '10px' : '15px'}
          marginRight={isMobile ? '10px' : '0px'}
        />
        {!isMobile && (
          <Text marginLeft="10px" /* hardcoded font size */>
            {urlPairs[candidateUrl.type] && urlPairs[candidateUrl.type].label}
          </Text>
        )}
      </Flex>
    </Link>
  );
};

export default SocialLink;
