import { ListItem } from '@chakra-ui/react';
import React, { FC } from 'react';

import { HelpResource } from '../../types/contentful';

import { HelpSection } from './HelpSection';
import { ResourceContact } from './ResourceContact';

interface HelpNowSectionProps {
  resources: HelpResource[];
}

export const HelpNowSection: FC<HelpNowSectionProps> = ({ resources }) => (
  <HelpSection
    title="Get nearby help now"
    items={resources.map((r) => (
      <ListItem key={r.name}>
        <ResourceContact
          title={r.name}
          website={r.url}
          websiteAria={`Navigate to the website of ${r.name} in a new tab`}
          phone={r.phone}
          phoneAria={`Call the phone number of ${r.name}`}
          px={['4', '5', '6']}
          py={['10', '2', '3']}
        />
      </ListItem>
    ))}
  />
);

export default HelpNowSection;
