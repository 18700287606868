import React, {
  createContext,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import { Action, favCandidatesReducer } from '../reducers';

const FAV_CANDIDATES_KEY = 'favoritedCandidates';
export interface FavoriteCandidate {
  id: string;
  name: string;
  position_id: string;
  election_id: string | undefined;
  level: string | undefined;
  positionName: string | undefined;
}
export type State = FavoriteCandidate[];

const favoritedCandidates: State = [];

// TODO: Found out way to not disable eslint
// eslint-disable-next-line no-unused-vars
export type Dispatch = (action: Action) => void;

interface FavoritesInterface {
  favoriteCandidates: State;
  dispatch: Dispatch;
}

const FavoritesContext = createContext<FavoritesInterface | undefined>(
  undefined,
);

const initializer = (): State => {
  const localData = localStorage.getItem(FAV_CANDIDATES_KEY);
  return localData ? JSON.parse(localData) : [];
};

const FavoritesContextProvider: FC = ({ children }): ReactElement => {
  const [favoriteCandidates, dispatch] = useReducer(
    favCandidatesReducer,
    favoritedCandidates,
    initializer,
  );
  useEffect(() => {
    localStorage.setItem(
      FAV_CANDIDATES_KEY,
      JSON.stringify(favoriteCandidates),
    );
  }, [favoriteCandidates]);

  return (
    <FavoritesContext.Provider
      value={{ favoriteCandidates: favoriteCandidates, dispatch }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};

export default FavoritesContextProvider;

export const useFavoritedCandidates = () => {
  const context = useContext(FavoritesContext);

  if (!context) {
    throw new Error(
      'useFavoritedCandidates must be used inside the FavoriteContext provider',
    );
  }

  return context;
};
