import React, { createContext, FC, useEffect, useState } from 'react';

import { BallotReadyAPI, Contentful } from '../api';
import { StateAbbreviation } from '../types';
import { Candidate, Election, Measure, Position } from '../types/ballotReady';
import {
  HelpResource,
  VotingAndRegistrationMethods,
  ParsedAuthority,
} from '../types/contentful';

import { useSavedAddress } from './AddressContext';

interface BallotDataContextInterface {
  elections: Election[];
  measures: Record<number, Measure[]>;
  positions: Record<number, Position[]>;
  resources: HelpResource[];
  votingMethods: VotingAndRegistrationMethods['votingMethods'];
  registrationMethods: VotingAndRegistrationMethods['registrationMethods'];
  electionDate: VotingAndRegistrationMethods['electionDate'];
  authority: ParsedAuthority;
  isLoading: boolean;
  candidates: Record<number, Candidate>;
}

const init = {
  elections: [],
  measures: {},
  positions: {},
  resources: [],
  votingMethods: [],
  registrationMethods: [],
  electionDate: '',
  authority: {} as ParsedAuthority,
  isLoading: true,
  candidates: {},
};

export const BallotDataContext =
  createContext<BallotDataContextInterface>(init);

export const BallotDataProvider: FC = ({ children }) => {
  const [ballotData, setBallotData] =
    useState<BallotDataContextInterface>(init);
  const { address } = useSavedAddress();

  useEffect(() => {
    const loadElections = async (
      lat: number,
      lng: number,
    ): Promise<Election[]> => {
      const res = await BallotReadyAPI.getElectionsByLatLong(lat, lng);

      if (!BallotReadyAPI.isError(res)) {
        return res.data.elections;
      }

      return [];
    };

    const loadPositions = async (
      lat: number,
      lng: number,
      elections: Election[],
    ): Promise<[Record<number, Position[]>, Record<number, Candidate>]> => {
      const res = await Promise.all(
        elections.map((election) =>
          BallotReadyAPI.getPositions(lat, lng, election.id),
        ),
      );

      const positions: Record<number, Position[]> = {};
      const candidates: Record<number, Candidate> = {};

      res.forEach((positionsRes, i) => {
        if (!BallotReadyAPI.isError(positionsRes)) {
          const position = positionsRes.data.positions;
          positions[elections[i].id] = position;

          const electionCandidates = position.reduce<Candidate[]>(
            (acc, position) => [...acc, ...position.candidates],
            [],
          );

          // Map candidates from the id to the candidate
          electionCandidates.forEach((candidate) => {
            candidates[candidate.id] = candidate;
          });
        }
      });

      return [positions, candidates];
    };

    const loadMeasures = async (
      lat: number,
      lng: number,
      elections: Election[],
    ): Promise<Record<number, Measure[]>> => {
      const res = await Promise.all(
        elections.map((election) =>
          BallotReadyAPI.getMeasures(lat, lng, election.id),
        ),
      );

      const measures: Record<number, Measure[]> = {};

      res.forEach((measuresRes, i) => {
        if (!BallotReadyAPI.isError(measuresRes)) {
          measures[elections[i].id] = measuresRes.data.positions;
        }
      });

      return measures;
    };

    const loadData = async (
      lat: number,
      lng: number,
      ocdId: string,
      state: StateAbbreviation,
    ): Promise<void> => {
      const elections = await loadElections(lat, lng);

      const [
        [positions, candidates],
        measures,
        resources,
        { votingMethods, registrationMethods, electionDate },
        authority,
      ] = await Promise.all([
        loadPositions(lat, lng, elections),
        loadMeasures(lat, lng, elections),
        Contentful.getHelpResources(state),
        Contentful.getVotingAndRegistrationMethods(ocdId),
        Contentful.getElectionAuthority(ocdId),
      ]);

      setBallotData({
        elections,
        measures,
        positions,
        resources,
        votingMethods,
        registrationMethods,
        electionDate,
        authority: authority as ParsedAuthority,
        isLoading: false,
        candidates: candidates,
      });
    };

    if (address) {
      setBallotData((data) => ({ ...data, isLoading: true }));
      loadData(address.lat, address.lng, address.ocdId, address.state);
    }
  }, [address]);

  return (
    <BallotDataContext.Provider value={ballotData}>
      {children}
    </BallotDataContext.Provider>
  );
};

/**
 * A hook to get the ballot data context.
 *
 * @returns The ballot data context.
 */
export const useBallotData = (): BallotDataContextInterface => {
  const context = React.useContext(BallotDataContext);

  if (context === undefined) {
    throw new Error('useBallotData must be used within a BallotDataProvider');
  }

  return context;
};
