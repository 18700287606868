import { chakra, List, Text } from '@chakra-ui/react';
import type { ListItemProps } from '@chakra-ui/react';
import React, { FC } from 'react';

interface HelpSectionProps {
  title: string;
  items: ListItemProps[];
}

export const HelpSection: FC<HelpSectionProps> = ({ title, items }) => (
  <chakra.section w="100%" mt={5}>
    <Text as="h2" textStyle="h2">
      {title}
    </Text>
    <List>{items.map((i) => i)}</List>
  </chakra.section>
);
