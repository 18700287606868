import React, {
  createContext,
  FC,
  ReactElement,
  useContext,
  useState,
  useEffect,
} from 'react';

import { StateAbbreviation } from '../types';

const ADDRESS_KEY = 'x-brink-cache-address';

interface Address {
  fullAddress: string;
  lat: number;
  lng: number;
  ocdId: string;
  state: StateAbbreviation;
  zipcode: string;
}

interface AddressInterface {
  address: Address | null;
  setAddress: React.Dispatch<React.SetStateAction<Address | null>>;
}

const AddressContext = createContext<AddressInterface | undefined>(undefined);

const AddressProvider: FC = ({ children }): ReactElement => {
  const [address, setAddress] = useState<Address | null>(() => {
    const cachedAddress = localStorage.getItem(ADDRESS_KEY);

    if (cachedAddress) {
      return JSON.parse(cachedAddress);
    }

    return null;
  });

  useEffect(() => {
    localStorage.setItem(ADDRESS_KEY, JSON.stringify(address));
  }, [address]);

  return (
    <AddressContext.Provider value={{ address, setAddress }}>
      {children}
    </AddressContext.Provider>
  );
};

export default AddressProvider;

export const useSavedAddress = () => {
  const context = useContext(AddressContext);

  if (!context) {
    throw new Error(
      'useSavedAddress must be used inside the AddressContext provider',
    );
  }
  return context;
};
