import { getCLS, getFCP, getFID, getLCP, getTTFB } from 'web-vitals';

interface MetricObj {
  name: string;
  id: string;
  value: any;
}

interface MetricOptions {
  params?: Record<string, string>;
  path?: string;
  analyticsId?: string;
  debug: boolean;
}

const VITALS_URL = 'https://vitals.vercel-analytics.com/v1/vitals';

const getConnectionSpeed = () => {
  const connection: any = navigator?.connection;
  const effectiveType = connection?.effectiveType;
  return effectiveType ?? '';
};

const sendToAnalytics = (metric: MetricObj, options: MetricOptions) => {
  const page = Object.entries(options.params ?? {}).reduce(
    (acc, [key, value]) => acc?.replace(value, `[${key}]`),
    options.path,
  );

  const body = {
    dsn: options.analyticsId ?? '', // qPgJqYH9LQX5o31Ormk8iWhCxZO
    id: metric.id, // v2-1653884975443-1839479248192
    page: page ?? '', // /blog/[slug]
    href: location.href, // https://my-app.vercel.app/blog/my-test
    event_name: metric.name, // TTFB
    value: metric.value.toString(), // 60.20000000298023
    speed: getConnectionSpeed(), // 4g
  };

  if (options.debug) {
    console.log('[Analytics]', metric.name, JSON.stringify(body, null, 2));
  }

  const blob = new Blob([new URLSearchParams(body).toString()], {
    // This content type is necessary for `sendBeacon`
    type: 'application/x-www-form-urlencoded',
  });

  if (navigator.sendBeacon) {
    navigator.sendBeacon(VITALS_URL, blob);
  } else {
    fetch(VITALS_URL, {
      body: blob,
      method: 'POST',
      credentials: 'omit',
      keepalive: true,
    });
  }
};

export const sendWebVitals = (options: MetricOptions) => {
  try {
    getFID((metric) => sendToAnalytics(metric, options));
    getTTFB((metric) => sendToAnalytics(metric, options));
    getLCP((metric) => sendToAnalytics(metric, options));
    getCLS((metric) => sendToAnalytics(metric, options));
    getFCP((metric) => sendToAnalytics(metric, options));
  } catch (err) {
    console.error('[Analytics]', err);
  }
};
