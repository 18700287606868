import React, { ReactElement, useEffect, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useParams } from 'react-router-dom';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import {
  Button,
  Link,
  Flex,
  Text,
  VStack,
  HStack,
  Icon,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  Spinner,
} from '@chakra-ui/react';

import type { HelpContent, VotingMethodType } from '../../types/contentful';
import { Contentful } from '../../api';
import { useVotingMethod } from '../../contexts';
import { BackButton } from '../../components';
import { votingTypePairs } from '../../components/card/VotingMethodCard';
import { markdownTheme } from '../../markdown';
import { Page } from '../../layout';

const PAGE_TITLE = 'Voting Method Information';

export const VotingMethodInfoPage = (): ReactElement => {
  const { votingMethod } = useParams();
  const [info, setInfo] = useState<HelpContent>();
  const [isLoading, setIsLoading] = useState(true);

  const { setVotingMethod } = useVotingMethod();

  useEffect(() => {
    const fetchVotingMethod = async (method: string) => {
      const votingMethods = await Contentful.getHelpContent('voting_method');

      const vmd = votingMethods.filter((v) => v.slug === method).at(0);
      setInfo(vmd);
      setIsLoading(false);
    };

    if (votingMethod) {
      fetchVotingMethod(votingMethod);
    }
  }, [votingMethod]);

  if (isLoading) {
    return (
      <Page
        justifyContent="center"
        m="20vh auto"
        title={PAGE_TITLE}
        ariaLabel={`${votingMethod} method information`}
        aria-live="polite"
        aria-busy="true"
      >
        <Spinner speed="1s" size="xl" />
      </Page>
    );
  }

  if (!info) {
    return (
      <Page
        m="30px auto"
        title={PAGE_TITLE}
        ariaLabel={`${votingMethod} method information`}
        aria-busy="false"
      >
        <Alert flexDir="column" variant="subtle" status="warning" m="auto">
          <AlertIcon boxSize="40px" />
          <AlertTitle>Failed to find voting method</AlertTitle>
          <AlertDescription>
            This is likely an error with the Contentful API
          </AlertDescription>
        </Alert>
      </Page>
    );
  }

  return (
    <main>
      <BackButton text="Back to all voting methods" />
      <Flex justifyContent="center" marginBottom="25px">
        <VStack alignItems="flex-start" w={['90%', '80%', '60%']} maxW="700px">
          <HStack alignSelf="center">
            <Icon
              color="brand.blue"
              boxSize="1.5em"
              as={votingTypePairs[votingMethod as VotingMethodType].icon}
            />
            <Text as="h1" textStyle="h1">
              {votingTypePairs[votingMethod as VotingMethodType].label}
            </Text>
          </HStack>
          <Button
            onClick={() => setVotingMethod(votingMethod as VotingMethodType)}
            href="/home/checklist"
            as={Link}
            variant="accent"
            alignSelf="center"
          >
            {votingTypePairs[votingMethod as VotingMethodType].selectLabel}
          </Button>
          <ReactMarkdown skipHtml components={ChakraUIRenderer(markdownTheme)}>
            {info.body}
          </ReactMarkdown>
        </VStack>
      </Flex>
    </main>
  );
};

export default VotingMethodInfoPage;
