import React, { FC, ReactElement } from 'react';
import { VStack, Text } from '@chakra-ui/react';

import { BackLink } from '../link/BackLink';

interface DisclaimerProps {
  heading: string;
  description: string;
  hyperlink?: string;
}

export const Disclaimer: FC<DisclaimerProps> = ({
  heading,
  description,
  hyperlink,
}): ReactElement => (
  <VStack
    marginTop="0px"
    width="100%"
    marginBottom="20px"
    alignItems="flex-end"
  >
    <Text as="h2" textStyle="h2" alignSelf="flex-start">
      {heading}
    </Text>
    <Text paddingBottom="0px">{description}</Text>
    {hyperlink && (
      <BackLink
        paddingBottom="20px"
        left={['0', '0']}
        href={hyperlink}
        text="Go to Help"
        isRightLink
      />
    )}
  </VStack>
);
