import { VStack, Text, Link } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ElectionCard, TodoItem } from '../../components';
import {
  useChecklist,
  useSavedAddress,
  useVotingMethod,
  useBallotData,
} from '../../contexts';
import { checkItems, todos } from '../../constants/checkItems';
import { capitalize } from '../../utils/helpers';
import { Contentful } from '../../api';
import type { ParsedAuthority } from '../../types/contentful';
import { Page } from '../../layout';

const VotingTypeHeader = {
  'by-mail': 'Mail-In Voting Checklist',
  'early-voting': 'Early-Voting Checklist',
  'in-person': 'In-Person Voting Checklist',
};

export const ElectionChecklistPage = () => {
  const { storedItems, dispatch } = useChecklist();
  const { votingMethod } = useVotingMethod();
  const { address } = useSavedAddress();
  const { elections } = useBallotData();
  const [count, setCount] = useState(0);
  const [authority, setAuthority] = useState<{} | ParsedAuthority>({});
  const navigate = useNavigate();

  const numCheckItems = (): number => {
    if (!votingMethod) {
      return 0;
    }

    return todos[votingMethod].reduce(
      (acc, todo) => (acc += todo.data.length),
      0,
    );
  };

  useEffect(() => {
    const getAuthority = async (ocdId: string) =>
      setAuthority(await Contentful.getElectionAuthority(ocdId));

    if (address) {
      getAuthority(address.ocdId);
    }
  }, [address]);

  useEffect(() => {
    let count = 0;

    if (votingMethod) {
      count = todos[votingMethod].reduce((acc, todo) => {
        const completed = todo.data.filter((item) =>
          storedItems.includes(item),
        );

        return acc + completed.length;
      }, 0);
    }

    setCount(count);
  }, [storedItems, votingMethod]);

  if (!votingMethod) {
    navigate('/home/select', { replace: true });
    return null;
  }

  return (
    <Page
      justifyContent="center"
      title="Checklist"
      ariaLabel="election checklist page"
    >
      <VStack w="100%">
        <Text as="h1" textStyle="h1" alignSelf="flex-start">
          {VotingTypeHeader[votingMethod]}
        </Text>
        <Text alignSelf="flex-start">
          Change your mind?{' '}
          <Link variant="inline" href="/home/select">
            Update your voting method
          </Link>
        </Text>
        {elections.map((election) => (
          <ElectionCard election={election} width="100%" key={election.id} />
        ))}
        <Text color="grey" alignSelf="flex-start">
          {count} out of {numCheckItems()} action items checked
        </Text>
      </VStack>
      {todos[votingMethod].map((level) => (
        <VStack key={level.key}>
          {level.data.length > 0 && (
            <Text
              as="h4"
              alignSelf="flex-start"
              textStyle="h4"
              variant={level.key === 'register' ? 'tagAlert' : 'tagInfo'}
            >
              {capitalize(level.key)}
            </Text>
          )}
          {level.data.map((todo) => (
            <TodoItem
              w="100%"
              itemId={todo}
              item={checkItems[todo]}
              authority={authority}
              dispatch={dispatch}
              key={checkItems[todo].title}
              defaultChecked={storedItems.includes(todo)}
            />
          ))}
        </VStack>
      ))}
    </Page>
  );
};
export default ElectionChecklistPage;
