import { ListItem } from '@chakra-ui/react';
import React, { FC } from 'react';

import { HelpContent } from '../../types/contentful';
import BallotLink from '../link/BallotLink';

import { HelpSection } from './HelpSection';

interface HelpPollingSectionProps {
  items: HelpContent[];
}

export const HelpPollingSection: FC<HelpPollingSectionProps> = ({ items }) => (
  <HelpSection
    title="Resolve polling place issues"
    items={items.map((item, index) => (
      <ListItem key={item.title}>
        <BallotLink
          href={`/home/help/polling/${index}`}
          width="100%"
          isTruncated={false}
          padding={['4', '5', '6']}
          maxHeight="fit-content"
          mt={3}
        >
          {item.title}
        </BallotLink>
      </ListItem>
    ))}
  />
);
