import React, { FC, ReactElement } from 'react';
import { ListItem } from '@chakra-ui/react';
import type { ListItemProps } from '@chakra-ui/react';

const DefaultNavItem: FC<ListItemProps> = ({
  children,
  ...rest
}): ReactElement => (
  <ListItem listStyleType="none" marginLeft="20px" marginRight="20px" {...rest}>
    {children}
  </ListItem>
);

interface NavItemProps extends ListItemProps {
  variant?: 'default' | 'accent' | 'none';
}

export const NavItem: FC<NavItemProps> = ({
  variant = 'none',
  children,
  ...rest
}): ReactElement => {
  if (variant === 'none') {
    return <ListItem {...rest}>{children}</ListItem>;
  } else if (variant === 'default') {
    return <DefaultNavItem {...rest}>{children}</DefaultNavItem>;
  }

  return (
    <DefaultNavItem
      padding="12px 20px"
      borderRadius="5px"
      backgroundColor="base"
      {...rest}
    >
      {children}
    </DefaultNavItem>
  );
};
