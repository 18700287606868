import {
  Box,
  FormControl,
  FormLabel,
  Kbd,
  Switch,
  useColorMode,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import React, { Fragment, ReactElement } from 'react';

export const AccessibilityForm = (): ReactElement => {
  const modeProps = useColorMode();

  const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);

  return (
    <Fragment>
      {/* <Box>
        <FormLabel
          id="language-select"
          fontWeight="bold"
          fontSize="16px"
          mb={3}
        >
          Language
        </FormLabel>
        <Select
          aria-labelledby="language-select"
          focusBorderColor="brand.blue"
        >
          <chakra.option bg={modeProps.colorMode === 'light' ? 'white' : 'black'} value="english">English</chakra.option>
          <chakra.option bg={modeProps.colorMode === 'light' ? 'white' : 'black'} value="spanish">Spanish</chakra.option>
        </Select>
      </Box> */}
      <Box>
        <FormLabel fontWeight="bold" id="text-size-slider" mb={3}>
          Text Size
        </FormLabel>
        Zoom In: <Kbd>{isMacLike ? '⌘' : 'Ctrl'}</Kbd> + <Kbd>+</Kbd> <br />
        Zoom Out: <Kbd>{isMacLike ? '⌘' : 'Ctrl'}</Kbd> + <Kbd>-</Kbd>
      </Box>
      <Box>
        <FormLabel fontWeight="bold" id="toggle-high-contrast" mb={3}>
          High Contrast
        </FormLabel>

        <FormControl display="flex" alignItems="center">
          <Switch
            mr={3}
            aria-labelledby="toggle-high-contrast"
            // backgroundColor={mode('brand.blue', 'brand.yellow')(modeProps)}
            onChange={modeProps.toggleColorMode}
            isChecked={modeProps.colorMode === 'dark'}
            colorScheme="cyan"
          />
          <FormLabel htmlFor="toggle-high-contrast" mb="0">
            {mode('Off', 'On')(modeProps)}
          </FormLabel>
        </FormControl>
      </Box>
    </Fragment>
  );
};
