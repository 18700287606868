import React, { FC } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import type { To } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import type { ButtonProps } from '@chakra-ui/react';

const NAVIGATE_BACK_VALUE = -1;

interface BackButtonProps extends ButtonProps {
  to?: To;
  text?: string;
}

export const BackButton: FC<BackButtonProps> = ({
  to,
  text = 'Back',
  ...rest
}) => {
  const navigate = useNavigate();

  const handleClick = () => (to ? navigate(to) : navigate(NAVIGATE_BACK_VALUE));

  return (
    <Button
      onClick={handleClick}
      variant="back"
      leftIcon={<ChevronLeftIcon />}
      {...rest}
    >
      {text}
    </Button>
  );
};
