import React, { FC, ReactElement } from 'react';
import { Image } from '@chakra-ui/react';
import type { ImageProps } from '@chakra-ui/react';

export const NavLogo: FC<ImageProps> = ({ ...rest }): ReactElement => (
  <Image
    alt="Redirects to landing page"
    height="30px"
    fit="scale-down"
    {...rest}
  />
);
