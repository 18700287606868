import { SimpleGrid, Wrap } from '@chakra-ui/react';
import React, { FC, ReactElement } from 'react';

import { useIsDesktop } from '../../hooks';

interface CandidateLayoutProps {
  numCandidates: number;
}
const MAX_CANDIDATE_GRID_WIDTH = new Array(3)
  .fill(null)
  .map((_, idx) => `calc(${6 - idx} * 2.5rem + ${6 - idx + 1} * 240px)`);

const CandidateLayout: FC<CandidateLayoutProps> = ({
  children,
  numCandidates,
}): ReactElement => {
  const isDesktop = useIsDesktop();

  return numCandidates < 4 && isDesktop ? (
    <Wrap spacing={10}>{children}</Wrap>
  ) : (
    <SimpleGrid
      // TODO: Make candidate width a constant so that the styles change if candidate width variable is changedf
      minChildWidth={isDesktop ? '240px' : '100%'}
      columns={[1, 2, 3, 4, 5, 6]}
      mb={5}
      maxWidth={MAX_CANDIDATE_GRID_WIDTH}
      spacing={isDesktop ? 10 : 0}
    >
      {children}
    </SimpleGrid>
  );
};

export default CandidateLayout;
