import React, { ReactElement } from 'react';

import { useIsMobile } from '../hooks';

import DesktopFooter from './DesktopFooter';
import MobileFooter from './MobileFooter';

export const Footer = (): ReactElement => {
  const isMobile = useIsMobile();

  return isMobile ? <MobileFooter /> : <DesktopFooter />;
};
