import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertTitle,
} from '@chakra-ui/react';
import type {
  AlertProps as ChakraAlertProps,
  AlertDescriptionProps,
  AlertTitleProps,
} from '@chakra-ui/react';
import React, { FC, ReactElement } from 'react';

interface AlertProps extends ChakraAlertProps {
  title?: string;
  body?: ReactElement;
  titleProps?: AlertTitleProps;
  descriptionProps?: AlertDescriptionProps;
  fontColor?: string;
}

export const Alert: FC<AlertProps> = ({
  children,
  title = '',
  body,
  titleProps,
  descriptionProps,
  ...rest
}): ReactElement => (
  <ChakraAlert {...rest}>
    <AlertTitle {...titleProps}>{title || body}</AlertTitle>
    <AlertDescription {...descriptionProps}>{children}</AlertDescription>
  </ChakraAlert>
);
