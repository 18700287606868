import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useColorMode,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import React, { FC, ReactElement } from 'react';

import { AccessibilityForm } from '../form/AccessibilityForm';

export const OptionsPopover: FC = ({ children }): ReactElement => {
  const modeProps = useColorMode();

  return (
    <Popover>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent zIndex="overlay" marginRight={5}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody
          bg={mode('brand.lightBase', 'black')(modeProps)}
          padding={8}
        >
          <Stack spacing="30px">
            <AccessibilityForm />
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default OptionsPopover;
