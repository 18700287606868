import React, { ReactElement } from 'react';
import {
  chakra,
  UnorderedList,
  Icon,
  Flex,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { ExternalLinkIcon } from '@chakra-ui/icons';

export const DesktopFooter = (): ReactElement => {
  const modeProps = useColorMode();

  const linkItems = [
    {
      href: 'https://www.brinkapp.co',
      title: 'Download Mobile App',
    },
    {
      href: 'https://www.facebook.com/brinkelectionguide',
      title: 'Facebook',
    },
    {
      href: 'https://twitter.com/BrinkVoting',
      title: 'Twitter',
    },
    {
      href: 'https://www.youtube.com/channel/UC1vQ1pxG6RH_ook517newHQ',
      title: 'YouTube',
    },
  ];

  return (
    <chakra.nav
      top="10"
      zIndex="overlay"
      aria-label="primary navigation"
      height="navHeight"
    >
      <UnorderedList
        marginLeft="0"
        listStyleType="none"
        backgroundColor={mode('brand.primary', 'black')(modeProps)}
        alignItems="center"
        height="navHeight"
        display="flex"
        borderTop={'1px solid grey'}
      >
        <Flex alignItems="center" justifyContent="space-evenly" width="100%">
          {linkItems.map((item) => (
            <chakra.a
              key={item.title}
              color="white"
              href={item.href}
              target="_blank"
            >
              <Text as="b">{item.title}</Text>
              <Icon
                as={ExternalLinkIcon}
                w={4}
                h={4}
                mb={1}
                color="white"
                marginLeft="10px"
              />
            </chakra.a>
          ))}
        </Flex>
      </UnorderedList>
    </chakra.nav>
  );
};

export default DesktopFooter;
