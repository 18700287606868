import { Link } from '@chakra-ui/react';
import type { LinkProps } from '@chakra-ui/react';
import React, { FC } from 'react';

interface SkipContentLinkProps extends LinkProps {
  text: string;
}

export const SkipContentLink: FC<SkipContentLinkProps> = ({
  text,
  href,
  ...rest
}) => (
  <Link
    padding="0px 20px"
    height="50px"
    lineHeight="50px"
    textAlign="center"
    borderRadius={'5px'}
    bg="blue"
    color="white"
    zIndex="skipLink"
    left="0%"
    position="absolute"
    transform="translateY(-100%)"
    _focus={{
      transform: 'translateY(0%)',
    }}
    href={href}
    {...rest}
    textStyle="h4"
  >
    {text}
  </Link>
);
