import { mode } from '@chakra-ui/theme-tools';
import type { StyleConfig } from '@chakra-ui/theme-tools';

export const AlertStyles: StyleConfig = {
  baseStyle: (props) => ({
    container: {
      flexDirection: 'column',
      alignItems: 'left',
      background: mode('brand.base', 'black')(props),
      backgroundColor: mode('brand.base', 'black')(props),
      border: '1px',
      borderColor: mode('brand.darkBase', 'white')(props),
      borderRadius: '5px',
      padding: '25px',
    },
    title: {
      color: mode('brand.primary', 'white')(props),
      textStyle: 'h4',
    },
  }),
  variants: {
    'todo-alert': (props) => ({
      container: {
        backgroundColor: mode('brand.lightBase', 'black')(props),
        borderColor: mode('brand.blue', 'white')(props),
      },
    }),
    help: (props) => ({
      container: {
        backgroundColor: mode('brand.lightGray', 'black')(props),
        borderColor: mode('brand.midLightGray', 'white')(props),
      },
      title: {
        color: mode('brand.black', 'white')(props),
      },
    }),
  },
};
