import type { ThemeComponents } from '@chakra-ui/react';

import { TabStyles } from './tabs.theme';
import { AccordionStyles } from './accordion.theme';
import { BreadcrumbStyles } from './breadcrumb.theme';
import { ButtonStyles } from './button.theme';
import { LinkStyles } from './link.theme';
import { TextStyles } from './text.theme';
import { AlertStyles } from './alert.theme';
import { CheckboxStyles } from './checkbox.theme';

export const components: ThemeComponents = {
  Tabs: TabStyles,
  Accordion: AccordionStyles,
  Breadcrumb: BreadcrumbStyles,
  Button: ButtonStyles,
  Link: LinkStyles,
  Text: TextStyles,
  Alert: AlertStyles,
  Checkbox: CheckboxStyles,
};

export default components;
