import { Box, Flex, Image, Text, useColorMode } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import type { BoxProps } from '@chakra-ui/react';
import React, { FC, Fragment } from 'react';

import BallotLink from '../link/BallotLink';
import type { BallotLinkProps } from '../link/BallotLink';
import { PartyTag } from '../tag/PartyTag';
import { useIsMobile } from '../../hooks/useBreakpoint';

interface CandidateCardProps extends BallotLinkProps {
  candidate?: {
    id: number;
    name: string;
    image: string;
    party: string;
  };
  empty?: boolean;
}

const EmptyPicture: FC<BoxProps> = ({ ...rest }) => (
  <Box bg="base" w="150px" h="150px" borderRadius="50%" {...rest} />
);

export const MobileCandidateCard: FC<CandidateCardProps> = ({
  candidate,
  empty,
  href,
  ...rest
}) => {
  const isMobile = useIsMobile();

  const modeProps = useColorMode();

  const renderPicture = () => {
    if (empty) {
      return null;
    } else if (candidate?.image) {
      return (
        <Image
          objectFit="cover"
          src={candidate.image}
          w="35px"
          h="35px"
          borderRadius="50%"
        />
      );
    }

    return <EmptyPicture w="35px" h="35px" />;
  };

  const renderContent = () => {
    if (empty) {
      return (
        <Text color={mode('gray', 'white')(modeProps)} textStyle="h4">
          No favorites for this race
        </Text>
      );
    } else if (candidate) {
      return (
        <Fragment>
          <Text
            color={mode('black', 'white')(modeProps)}
            as="h4"
            textStyle="h4"
          >
            {candidate.name}
          </Text>
          <PartyTag shortname={isMobile} party={candidate.party} />
        </Fragment>
      );
    }
  };

  return (
    <BallotLink mt={5} width="100%" href={href} {...rest}>
      {renderPicture()}
      <Flex ml={3} alignItems="center">
        {renderContent()}
      </Flex>
    </BallotLink>
  );
};
