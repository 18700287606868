import React, { FC, useEffect } from 'react';
import { chakra, useTheme, VStack } from '@chakra-ui/react';
import type { StackProps, BoxProps } from '@chakra-ui/react';

import { useIsDesktop } from '../hooks';

interface PageProps extends StackProps {
  title: string;
  mainProps?: BoxProps;
  ariaLabel: string;
}

export const Page: FC<PageProps> = ({
  children,
  title,
  id,
  ariaLabel,
  mainProps,
  ...rest
}) => {
  const { sizes } = useTheme();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    document.title = `${title} | Brink`;
  }, [title]);

  const getPageHeight = (): string => {
    const tabHeight = isDesktop ? sizes.tabHeight : sizes.tabHeightMobile;

    return `calc(100vh - ${sizes.navHeight} - ${tabHeight} - ${sizes.navHeight} - 30px)`;
  };

  return (
    <>
      <chakra.div
        marginTop={`-${sizes.navHeight}`}
        paddingTop={`${sizes.navHeight}`}
        id="skip-nav"
      />
      <chakra.main
        // border="solid red 2px"
        minH={getPageHeight()}
        // minH="100vh"
        id={id}
        mt="30px"
        w="100%"
        tabIndex={-1}
        aria-label={ariaLabel}
        {...mainProps}
      >
        <VStack
          alignItems="flex-start"
          spacing="30px"
          m="auto"
          w={['90%', '75%', '60%', '50%']}
          mb="10px"
          {...rest}
        >
          {children}
        </VStack>
      </chakra.main>
    </>
  );
};

export default Page;
