import React, { useState, useEffect, ChangeEvent } from 'react';
import { Center, Flex, Text, VStack, Select } from '@chakra-ui/react';
import PlaceIcon from '@mui/icons-material/Place';
import pluralize from 'pluralize';

import { GoogleCivicAPI } from '../../api/googleCivic';
import { useSavedAddress } from '../../contexts';
import { Election, PollingLocation } from '../../types/googleCivic';
import PollingPlace from '../../components/pollingLocations/PollingPlace';
import { Page } from '../../layout';
import {
  formatPollingLocationTitle,
  formatPollingLocationAddress,
  findPollingLocationHours,
  formatPollingLocationDate,
} from '../../utils/helpers';

export const PollingPlaces = () => {
  const { address } = useSavedAddress();
  const [elections, setElections] = useState<Election[]>([]);
  const [pollingPlaces, setPollingPlaces] = useState<PollingLocation[]>([]);
  const [displayOpen, setDisplayOpen] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<string>(
    formatPollingLocationDate(new Date()),
  );

  const numUpcomingDays = 6;

  useEffect(() => {
    const loadElectionData = async () => {
      try {
        const res = await GoogleCivicAPI.getElections();
        if (res) {
          setElections(res);
        }
      } catch (error) {
        console.log(error);
      }
    };

    loadElectionData();
  }, []);

  useEffect(() => {
    if (!elections) {
      return;
    }

    const firstElection = { id: '8000' };
    const loadPollingPlaces = async () => {
      try {
        const isEarly = selectedDate !== 'Tue, Nov 8';
        const res = await GoogleCivicAPI.getVoterInfo(
          firstElection.id,
          address?.fullAddress,
          isEarly,
        );
        if (res.length) {
          setPollingPlaces(res);
        }
      } catch (error) {
        console.log(error);
      }
    };

    loadPollingPlaces();
  }, [elections, address, selectedDate]);

  const handleDropdownChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedDate(e.target.value);
    setDisplayOpen(e.target.value === formatPollingLocationDate(new Date()));
  };

  return (
    <Page title="Polling Locations" ariaLabel="Polling Locations page">
      <Center margin={'auto'}>
        <Flex marginRight="10px">
          <PlaceIcon sx={{ fontSize: 32 }} />
        </Flex>
        <Text textStyle={['h2', 'h1']}>Nearby Polling Locations</Text>
      </Center>
      <Select
        variant="filled"
        onChange={handleDropdownChange}
        defaultValue={'Select date to vote'}
      >
        <option disabled value={'Select date to vote'}>
          Select date to vote
        </option>
        {[...Array(numUpcomingDays).keys()].map((num: number) => {
          const today = new Date();
          today.setDate(today.getDate() + num);
          const display = formatPollingLocationDate(today);

          const stopDay = new Date('November 9, 2022');
          if (today > stopDay) {
            return;
          }

          return (
            <option key={num} value={display}>
              {display}
            </option>
          );
        })}
      </Select>
      <Flex
        minWidth="100%"
        margin-top="0px"
        flexDir="column"
        alignContent="flex-start"
        justify="flex-start"
      >
        <Text mt="4px" mb="15px" color="grey">
          {`${pluralize('polling places', pollingPlaces.length, true)}`}
        </Text>
        <VStack>
          {pollingPlaces.map((pollingPlace, i) => (
            <PollingPlace
              key={i}
              name={formatPollingLocationTitle(pollingPlace)}
              address={formatPollingLocationAddress(pollingPlace)}
              hours={findPollingLocationHours(
                pollingPlace.pollingHours,
                selectedDate,
              )}
              displayOpen={displayOpen}
              maplinkAria={'View this address on Google Maps'}
              mb={'10px'}
            />
          ))}
        </VStack>
        <VStack width="100%" spacing="15px">
          <Text as="h2" textStyle="h2" alignSelf="flex-start">
            Don't see your polling location?
          </Text>
          <Text>
            We source our information externally from the Google Civic API and
            one possible drawback of this is that the API may have incomplete
            data which could potentially result in incomplete data on the
            website. This issue may apply to races, positions, candidate lists,
            candidate information, and polling locations. So, if you cannot find
            information about your desired polling location, please be aware
            that we externally source our data and therefore it might be
            incomplete.
          </Text>
        </VStack>
      </Flex>
    </Page>
  );
};

export default PollingPlaces;
