import { CheckItem } from '../constants/checkItems';
import { ChecklistState } from '../contexts/ChecklistContext';

export interface ChecklistAction {
  type: 'add-check' | 'remove-check' | 'clear';
  id: CheckItem | null;
}

export const checklistReducer = (
  state: ChecklistState,
  action: ChecklistAction,
) => {
  switch (action.type) {
    case 'add-check':
      if (action.id) {
        state = [...state, action.id];
      }
      return state;
    case 'remove-check':
      state = state.filter((el) => el !== action.id);

      return state;
    case 'clear':
      state = [];
      return state;
    default:
      throw Error('Invalid action');
  }
};
