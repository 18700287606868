import React, { FC, ReactElement } from 'react';

import FavoritesContextProvider from './FavCandidatesContext';
import AddressProvider from './AddressContext';
import ChecklistContextProvider from './ChecklistContext';
import VotingMethodContextProvider from './VotingMethodContext';
import { BallotDataProvider } from './BallotContext';

const ProviderWrapper: FC = ({ children }): ReactElement => (
  <FavoritesContextProvider>
    <AddressProvider>
      <ChecklistContextProvider>
        <VotingMethodContextProvider>
          <BallotDataProvider>{children}</BallotDataProvider>
        </VotingMethodContextProvider>
      </ChecklistContextProvider>
    </AddressProvider>
  </FavoritesContextProvider>
);

export default ProviderWrapper;
