import { Button, Icon, useColorMode } from '@chakra-ui/react';
import { Star, StarBorder } from '@mui/icons-material';
import React, { FC, ReactElement, useState } from 'react';
import type { ButtonProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import { useFavoritedCandidates } from '../../contexts';
import { Candidate } from '../../types/ballotReady';

interface FavoriteButtonProps extends ButtonProps {
  candidate: Candidate;
  electionId?: string;
  level?: string;
  positionName?: string;
  [key: string]: unknown;
}

export const FavoriteButton: FC<FavoriteButtonProps> = ({
  candidate,
  electionId,
  level,
  positionName,
  ...rest
}): ReactElement => {
  const { favoriteCandidates, dispatch } = useFavoritedCandidates();
  const name = `${candidate.first_name} ${candidate.last_name}`;
  const defaultChecked = favoriteCandidates.some((el) => el.name === name);
  const [isChecked, setIsChecked] = useState(defaultChecked ?? false);

  const modeProps = useColorMode();

  const handleStarClick = () => {
    dispatch({
      type: !isChecked ? 'add-favorite' : 'remove-favorite',
      favorite: {
        id: String(candidate.id),
        name: `${candidate.first_name} ${candidate.last_name}`,
        position_id: String(candidate.position_id),
        election_id: electionId,
        level: `${level}`,
        positionName: positionName,
      },
    });

    setIsChecked(!isChecked);
  };

  return (
    <Button
      background="none"
      padding={0}
      _hover={{ background: 'none' }}
      _active={{ background: 'none' }}
      onClick={handleStarClick}
      aria-label={`Add ${candidate.first_name} ${candidate.last_name} to favorites`}
      {...rest}
    >
      {isChecked ? (
        <Icon
          as={Star}
          w="32px"
          h="32px"
          color={mode('brand.blue', 'brand.yellow')(modeProps)}
        />
      ) : (
        <Icon
          as={StarBorder}
          w="32px"
          h="32px"
          color={mode('brand.blue', 'brand.yellow')(modeProps)}
        />
      )}
    </Button>
  );
};

export default FavoriteButton;
