import type { StyleConfig } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

export const AccordionStyles: StyleConfig = {
  variants: {
    faq: (props) => ({
      button: {
        color: mode('brand.primary', 'brand.white')(props),
        _hover: { backgroundColor: mode('brand.base', 'black')(props) },
        fontWeight: 'bold',
        _focus: { color: mode('brand.primary', 'brand.white')(props) },
        padding: ['12px 16px', '20px 36px'],
        textStyle: 'h4',
      },
      panel: {
        marginTop: 1,
        textColor: mode('brand.black', 'brand.white')(props),
        pt: 1,
        px: ['16px', '36px'],
        whiteSpace: 'pre-wrap',
      },
      container: {
        borderStyle: 'none',
      },
      root: {
        border: 'solid 1px',
        borderColor: mode('brand.darkBase', 'white')(props),
        borderRadius: '5px',
        backgroundColor: mode('brand.base', 'black')(props),
      },
    }),
  },
};

export default AccordionStyles;
