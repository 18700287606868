import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Link, Text } from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';

import { Contentful } from '../../api';
import {
  HelpAboutSection,
  HelpNowSection,
  HelpPollingSection,
} from '../../components';
import { useBallotData, useSavedAddress } from '../../contexts';
import { Page } from '../../layout';
import { HelpContent } from '../../types/contentful';

export const HelpPage = (): ReactElement => {
  const [helpContents, setHelpContents] = useState<HelpContent[]>([]);

  const { resources } = useBallotData();
  const { address } = useSavedAddress();

  useEffect(() => {
    const loadHelpContent = async () => {
      const content = await Contentful.getHelpContent('voting_right');

      setHelpContents(content);
    };

    loadHelpContent();
  }, []);

  return (
    <>
      <Page ariaLabel="help page" title="Help">
        <Box w="100%">
          <Text as="h1" textStyle="h1">
            Help
          </Text>
          <Text mr={1}>
            Are you facing a polling station issue?{' '}
            <Link
              href="https://www.surveymonkey.com/r/FFDTRXF"
              isExternal
              variant="inline"
            >
              File a polling complaint
              {/* Hiding the icon because it reads "Image" */}
              <ExternalLinkIcon ml={1} mb={1} aria-hidden="true" />
            </Link>
          </Text>
        </Box>

        {address && <HelpNowSection resources={resources} />}
        <HelpPollingSection items={helpContents} />
        <HelpAboutSection state={address?.state} zip={address?.zipcode} />
      </Page>
    </>
  );
};
